import React from "react";
import {COLOR_DARK_GREY, COLOR_WHITE, PRIMARY_BLUE, PRIMARY_LIGHT_BLUE} from './Theme.js';

export function Logo({inverse}: { inverse?: boolean }) {
    return <div className="w-52 h-16">
        <svg xmlns="http://www.w3.org/2000/svg" width="210" height="65" viewBox="0 0 210 65" fill="none">
            <g clipPath="url(#clip0_304_35629)">
                <path
                    d="M72.2985 46.2166C69.9679 44.6008 66.9284 43.7538 63.9611 42.9249C61.234 42.156 58.8372 41.4592 57.1553 40.2699C55.4734 39.0745 54.4703 37.4286 54.4642 34.6054C54.4642 32.2447 55.161 30.3526 56.5967 29.0311C58.0383 27.7096 60.2608 26.9467 63.3664 26.9467C66.7122 26.9467 69.5594 27.7756 71.2714 29.4335C71.4516 29.6077 71.6498 29.812 71.8961 29.9802C72.1424 30.1423 72.4427 30.2685 72.7911 30.2685C73.1876 30.2685 73.536 30.1483 73.7762 29.9081C74.0225 29.6678 74.1366 29.3194 74.1366 28.9169C74.1366 28.3643 73.8543 27.8898 73.4218 27.4573V27.4513C71.0551 25.3309 67.451 24.1355 63.3724 24.1355C59.6001 24.1355 56.6387 25.1086 54.6144 26.9107C52.5901 28.7127 51.5329 31.3377 51.5329 34.5514C51.5329 38.3417 53.0406 40.7925 55.2451 42.4263C57.4496 44.0662 60.3149 44.9252 63.0781 45.67C65.8352 46.4089 68.4121 47.0936 70.2682 48.301C72.1244 49.5144 73.2777 51.2083 73.2837 54.1036C73.2837 56.5124 72.4607 58.5126 70.8089 59.9242C69.151 61.3358 66.6281 62.1768 63.1982 62.1768C58.621 62.1768 55.4434 60.7712 53.5332 58.3505C53.347 58.1042 53.1487 57.8699 52.8965 57.6837C52.6442 57.4975 52.3438 57.3713 51.9954 57.3713C51.593 57.3713 51.2386 57.5095 51.0043 57.7678C50.764 58.0261 50.6499 58.3805 50.6499 58.7769C50.6499 59.2575 50.8541 59.72 51.1785 60.1044C53.7735 63.246 57.8821 64.988 63.3243 64.988C67.5411 64.988 70.7728 63.8527 72.9473 61.9065C75.1278 59.9663 76.233 57.2272 76.233 54.0916C76.239 50.2352 74.6472 47.8145 72.3166 46.2046L72.2985 46.2166Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_BLUE}/>
                <path
                    d="M105.396 61.8764C105.102 61.6182 104.693 61.474 104.207 61.474H85.1709V45.2075H97.569C98.0556 45.2075 98.4701 45.0573 98.7644 44.805C99.0587 44.5527 99.2209 44.1863 99.2209 43.8019C99.2209 43.4174 99.0587 43.051 98.7644 42.7987C98.4701 42.5404 98.0616 42.3963 97.569 42.3963H85.1709V27.6735H103.852C104.339 27.6735 104.753 27.5233 105.042 27.271C105.336 27.0188 105.498 26.6523 105.498 26.2679C105.498 25.8835 105.336 25.5171 105.042 25.2648C104.747 25.0065 104.339 24.8623 103.852 24.8623H83.6993C83.2668 24.8623 82.8943 25.0005 82.63 25.2588C82.3657 25.5231 82.2276 25.9015 82.2336 26.328V62.8375C82.2336 63.27 82.3657 63.6425 82.63 63.9068C82.8943 64.1711 83.2668 64.3092 83.6993 64.3032H104.207C104.693 64.3032 105.108 64.153 105.396 63.9007C105.69 63.6485 105.852 63.282 105.852 62.8976C105.852 62.5132 105.69 62.1468 105.396 61.8945V61.8764Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_BLUE}/>
                <path
                    d="M134.379 61.8764C134.085 61.6182 133.676 61.474 133.184 61.474H114.148V45.2075H126.546C127.033 45.2075 127.447 45.0573 127.741 44.805C128.036 44.5527 128.198 44.1863 128.198 43.8019C128.198 43.4174 128.036 43.051 127.741 42.7987C127.453 42.5404 127.039 42.3963 126.546 42.3963H114.148V27.6735H132.829C133.316 27.6735 133.73 27.5233 134.025 27.271C134.319 27.0188 134.481 26.6523 134.481 26.2679C134.481 25.8835 134.319 25.5171 134.025 25.2648C133.73 25.0065 133.322 24.8623 132.829 24.8623H112.676C112.244 24.8623 111.871 25.0005 111.607 25.2588C111.343 25.5231 111.205 25.9015 111.211 26.328V62.8375C111.211 63.27 111.343 63.6425 111.607 63.9068C111.871 64.1711 112.244 64.3092 112.676 64.3032H133.184C133.67 64.3032 134.085 64.153 134.379 63.9007C134.673 63.6485 134.836 63.282 134.836 62.8976C134.836 62.5132 134.673 62.1468 134.379 61.8945V61.8764Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_BLUE}/>
                <path
                    d="M167.176 62.1588L153.661 25.7033C153.409 24.9404 152.856 24.4298 152.147 24.4298C151.438 24.4298 150.886 24.9404 150.633 25.7033L137.118 62.1588C136.992 62.5372 136.932 62.9096 136.926 63.24C136.926 63.6364 137.046 63.9969 137.28 64.2491C137.52 64.5074 137.869 64.6456 138.271 64.6456C138.674 64.6456 139.01 64.5014 139.25 64.2732C139.497 64.0449 139.653 63.7506 139.779 63.4382L143.635 52.9683H160.629L164.485 63.4322C164.611 63.7446 164.773 64.0449 165.014 64.2732C165.254 64.5014 165.59 64.6456 165.993 64.6456C166.395 64.6456 166.75 64.5074 166.984 64.2491C167.224 63.9908 167.338 63.6364 167.338 63.24C167.338 62.9156 167.272 62.5372 167.146 62.1588H167.176ZM159.59 50.1511H144.705L152.147 29.9261L159.59 50.1511Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_LIGHT_BLUE}/>
                <path
                    d="M174.895 24.9764C174.637 24.6701 174.252 24.4899 173.838 24.4899C173.417 24.4899 173.039 24.6701 172.781 24.9764C172.516 25.2828 172.372 25.7093 172.372 26.1958V62.9396C172.372 63.4262 172.516 63.8527 172.781 64.159C173.039 64.4654 173.423 64.6456 173.838 64.6456C174.258 64.6456 174.637 64.4654 174.895 64.159C175.159 63.8527 175.303 63.4262 175.303 62.9396V26.1958C175.303 25.7093 175.159 25.2828 174.895 24.9764Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_LIGHT_BLUE}/>
                <path
                    d="M209.297 61.9365L200.497 46.7272C203.41 46.0965 205.632 44.7569 207.128 42.9248C208.678 41.0267 209.447 38.6179 209.447 35.9689C209.447 32.7192 208.299 29.92 206.161 27.9498C204.023 25.9796 200.911 24.8383 197.019 24.8383H185.047C184.614 24.8383 184.242 24.9764 183.978 25.2347C183.713 25.499 183.575 25.8774 183.581 26.3039V62.9276C183.581 63.4142 183.725 63.8407 183.99 64.147C184.248 64.4534 184.632 64.6336 185.047 64.6336C185.467 64.6336 185.846 64.4534 186.104 64.147C186.368 63.8407 186.513 63.4142 186.513 62.9276V47.0936H197.361L206.78 63.4562C206.966 63.7686 207.17 64.0569 207.44 64.2732C207.711 64.4954 208.047 64.6336 208.456 64.6336C208.858 64.6336 209.2 64.4954 209.423 64.2371C209.645 63.9848 209.747 63.6484 209.747 63.282C209.747 62.8015 209.549 62.357 209.303 61.9245L209.297 61.9365ZM206.503 35.9749C206.503 38.5038 205.68 40.5702 204.095 42.0118C202.509 43.4535 200.142 44.2884 197.007 44.2884H186.501V27.6675H197.007C200.166 27.6675 202.539 28.5325 204.113 29.9861C205.692 31.4458 206.497 33.5061 206.497 35.9749H206.503Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_LIGHT_BLUE}/>
                <path
                    d="M53.497 15.7019C52.9084 15.1192 51.9593 15.1252 51.3766 15.7139L22.8502 44.5107L15.1854 37.0922C14.5908 36.5156 13.6417 36.5336 13.065 37.1283C12.4884 37.723 12.5064 38.672 13.1011 39.2487L21.5407 47.418C21.9071 47.7724 22.3936 47.9586 22.8682 47.9526C23.3547 47.9526 23.8533 47.7604 24.2197 47.388L53.5091 17.8223C54.0917 17.2336 54.0857 16.2845 53.497 15.7019Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_LIGHT_BLUE}/>
                <path
                    d="M40.6122 61.7323H3.52601C3.23168 61.7323 2.99741 61.492 2.99741 61.2037V24.6761L25.0485 3.1656L39.561 17.534C40.1497 18.1166 41.0988 18.1106 41.6814 17.5219C42.2641 16.9333 42.2581 15.9842 41.6694 15.4015L26.8566 0.744848C26.364 0.252287 25.6973 0 25.0485 0C24.4058 0 23.745 0.24628 23.2525 0.732834L0.450513 22.9702C0.162185 23.2525 0 23.6369 0 24.0454V61.2037C0 63.1499 1.5798 64.7297 3.52601 64.7297H40.6122C41.4412 64.7297 42.1079 64.0569 42.1079 63.234C42.1079 62.4111 41.4352 61.7383 40.6122 61.7383V61.7323Z"
                    fill={inverse ? COLOR_WHITE : PRIMARY_BLUE}/>
            </g>
            <defs>
                <clipPath id="clip0_304_35629">
                    <rect width="209.741" height="65" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    </div>
}

const logoClassName = "object-scale-down w-52 max-h-32"
const logoLinkClassName = "my-6 md:my-2"

export function LogoLinkMassSave() {
    return <a href="https://www.masssave.com/" className={logoLinkClassName}>
        <img className={logoClassName} src="/design-assets/mass-save-partner.svg" alt="mass save partner"/>
    </a>
}
export function LogoLinkBPI() {
    return <a href="https://bpi.org/standards/" className={logoLinkClassName}>
        <img className={logoClassName} src="/design-assets/bpi.png" alt="BPI"/>
    </a>
}

export function LogoLinkILab() {
    return <a href="https://innovationlabs.harvard.edu/" className={logoLinkClassName}>
        <img className={logoClassName} src="/design-assets/harvard-innovation-lab.png" alt="harvar innovation lab"/>
    </a>
}

export function LogoLinkEWOR() {
    return <a href="https://www.ewor.com/" className={logoLinkClassName}>
        <img className={logoClassName} src="/design-assets/ewor.webp" alt="ewor"/>
    </a>
}
export function LogoLinkBerkeley() {
    return <a href="http://impel.lbl.gov/" className={logoLinkClassName}>
        <img className={logoClassName} src="/design-assets/berkeley-lab-alt.png" alt="berkeley-lab"/>
    </a>
}


export function IconHome() {
    return <img className="w-28" src="/design-assets/IconHome.svg" alt="home"/>
}

export function IconHeart() {
    return <img className="w-28" src="/design-assets/IconHeart.svg" alt="heart"/>
}

export function IconPriceTag() {
    return <img className="w-28" src="/design-assets/IconPriceTag.svg" alt="price-tag"/>
}

export function IconCalendar() {
    return <img className="w-28" src="/design-assets/IconCalendar.svg" alt="calendar"/>
}

export function IconPriceTagChecked() {
    return <img className="w-10 h-10" src="/assessment-icons/IconPriceTagChecked.svg" alt="price-checked"/>
}

export function IconPriceTagUnchecked() {
    return <img className="w-10 h-10" src="/assessment-icons/IconPriceTagUnchecked.svg" alt="price-unchecked"/>
}

export function IconLeafChecked() {
    return <img className="w-10 h-10" src="/assessment-icons/IconLeafChecked.svg" alt="leaf-checked"/>
}

export function IconLeafUnchecked() {
    return <img className="w-10 h-10" src="/assessment-icons/IconLeafUnchecked.svg" alt="leaf-unchecked"/>
}

export function IconHeartChecked() {
    return <img className="w-10 h-10" src="/assessment-icons/IconHeartChecked.svg" alt="heart-checked"/>
}

export function IconHeartUnchecked() {
    return <img className="w-10 h-10" src="/assessment-icons/IconHeartUnchecked.svg" alt="heart-unchecked"/>
}

export function NumberCircle({number, color}: { number: string, color: string }) {
    return <span
        className="p-4 pt-6 rounded-full h-12 w-12"
        style={{backgroundColor: color, color: COLOR_DARK_GREY, fontSize: "2rem", lineHeight: "0"}}>{number}</span>
}

export function ChevronRight() {
    return <img src="/design-assets/ChevronRight.svg" alt="right"/>
}

export function ChevronLeft() {
    return <img src="/design-assets/ChevronLeft.svg" alt="left"/>
}

export function IconAmbulance() {
    return <img src="/design-assets/IconAmbulance.svg" alt="ambulance"/>

}

export function IconTree() {
    return <img src="/design-assets/IconTree.svg" alt="tree"/>
}

export function IconCapitol() {
    return <img src="/design-assets/IconCapitol.svg" alt="capitol"/>

}