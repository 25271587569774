import {useUserSearch} from "./admin-utils.js";
import { DesignedButton, HStack, TextBase, VStack} from "@seeair/shared-components";
import {Loader, TextInput} from "@mantine/core";
import React from 'react';
import {trpc} from "~/lib-client";
import {AdminUserTable} from './AdminUserTable.js';
import {IconRefresh} from "@tabler/icons-react";
export function AdminUserList() {
    const {data:allUsers,refetch, isFetching} = trpc.ADMIN.listAllUsersAggregate.useQuery(undefined,{enabled:false})

    const {usersData, searchText, setSearchText} = useUserSearch(allUsers ?? [])
    return <VStack>
        <HStack>
            <DesignedButton onClick={refetch}><IconRefresh /></DesignedButton>
            <TextInput
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
            />
        </HStack>
        {
            isFetching
                ? <HStack center><Loader size='xl' /></HStack>
                : allUsers == null
                    ? <HStack center><TextBase>hit             <DesignedButton onClick={refetch}><IconRefresh /></DesignedButton>
                     to load</TextBase></HStack>
                    : <AdminUserTable users={usersData} />
        }

    </VStack>
}