import { z} from 'zod'

/* THE LAMBDA ENV */
export const EnvSchema = z.object({
    NODE_ENV: z.enum(['local', 'preview', 'production']),
    AWS_LAMBDA_FUNCTION_NAME: z.string(),
    AWS_LAMBDA_FUNCTION_VERSION: z.string(),
    WORKER_SENTRY_DSN: z.string().url(),
    SQS_EXTERNAL_SYNC_URL: z.string().url(),
    SECRET_NAME: z.string(),
    S3_BUCKET_ASSESSMENT_FILES: z.string(),
    S3_BUCKET_CAPTURE: z.string(),
    S3_BUCKET_PUBLIC: z.string(),
    BUCKET_SEEAIR_BIM:z.string(),
    ARN_S3_CAPTURE_QUEUE:z.string(),
    ARN_CAPTURE_WEBHOOK_QUEUE:z.string(),
    ARN_TYPEFORM_WEBHOOK_QUEUE:z.string(),
    ARN_UTILITYAPI_WEBHOOK_QUEUE:z.string(),
    ARN_HUBSPOT_WEBHOOK_QUEUE:z.string(),
    ARN_EXTERNAL_SYNC_QUEUE:z.string(),
    ARN_AUTODESK_WEBHOOK_QUEUE:z.string()

})
export type EnvData = z.infer<typeof EnvSchema>


/* THE SECRETS MANAGER RESPONSE */
export const SecretsSchema = z.object({
    STATSIG_SDK_KEY: z.string(),
    NEON_CONN_STRING: z.string().url(),
    CLOUDFLARE_KV_NAMESPACE: z.string(),
    CUBI_CAPTURE_API_KEY: z.string(),
    UTILITYAPI_API_TOKEN: z.string(),
    CF_ACCOUNT_ID: z.string(),
    GOOGLE_API_KEY: z.string(),
    HUBSPOT_API_TOKEN: z.string(),
    RENTCAST_API_KEY: z.string(),
    CLOUDFLARE_KV_API_TOKEN: z.string(),
    GOOGLE_SERVICE_ACCOUNT_JSON: z.string(),
    AUTODESK_CLIENT_ID:z.string(),
    AUTODESK_CLIENT_SECRET: z.string(),
})
export type SecretData = z.infer<typeof SecretsSchema>

export const TypeformAnswerFieldSchema = z.object({
    id: z.string(),
    type: z.enum(["number", "rating", "yes_no", "opinion_scale", "calendly", "multiple_choice", "legal", "number", "picture_choice", "date", "short_text", "email", "long_text", "dropdown", "ranking", "website", "file_upload", "payment"]),
    ref: z.string().optional()
})
export const TypeformAnswerSchema = z.discriminatedUnion('type', [
    z.object({
        type: z.literal("url"),
        url: z.string().url(),
        field: TypeformAnswerFieldSchema
    }),
    z.object({
        type: z.literal("number"),
        number: z.number(),
        field: TypeformAnswerFieldSchema
    }),
    z.object({
        type: z.literal("boolean"),
        boolean: z.boolean(),
        field: TypeformAnswerFieldSchema
    }),
    z.object({
        type: z.literal("choice"),
        choice: z.object({
            id: z.string(),
            label: z.string(),
            ref: z.string()
        }),
        field: TypeformAnswerFieldSchema
    }),
    z.object({
        type: z.literal("choices"),
        choices: z.object({
            ids: z.array(z.string()),
            labels: z.array(z.string()),
            refs: z.array(z.string())
        }),
        field: TypeformAnswerFieldSchema
    }),
    z.object({
        type: z.literal("date"),
        date: z.date(),
        field: TypeformAnswerFieldSchema
    }),
    z.object({
        type: z.literal("text"),
        text: z.string(),
        field: TypeformAnswerFieldSchema
    }),
    z.object({
        type: z.literal("email"),
        text: z.string().email(),
        field: TypeformAnswerFieldSchema
    }),
    z.object({
        type: z.literal("file_url"),
        file_url: z.string().url(),
        field: TypeformAnswerFieldSchema
    }),
    z.object({
        type: z.literal("payment"),
        payment: z.object({
            amount: z.number(),
            last4: z.number(),
            name: z.string(),
            success: z.boolean()
        }),
        field: TypeformAnswerFieldSchema
    })
])
export type TypeformAnswer = z.infer<typeof TypeformAnswerSchema>

export const TypeformFieldSchema = z.object({
    id: z.string(),
    ref: z.string(),
    type: z.string(),
    title: z.string(),
    properties: z.object({}).optional(),
    allow_multiple_selections: z.boolean().optional(),
    allow_other_choice: z.boolean().optional(),
    choices: z.array(
        z.object({
            id: z.string(),
            ref: z.string(),
            label: z.string()
        })
    ).optional()
})

export const TypeformMetaDataSchema = z.object({
    user_agent: z.string().optional(),
    platform: z.string().optional(),
    referer: z.string().optional(),
    network_id: z.string().optional(),
    browser: z.string().optional(),
});

export const AssessmentOnboardingHiddenSchema = z.object({
    email: z.string(),
    assessment_id: z.string()
});

export const TypeformItemSchema = z.object({
    landing_id: z.string(),
    token: z.string(),
    response_id: z.string(),
    response_type: z.string(),
    landed_at: z.string(),
    submitted_at: z.string(),
    metadata: TypeformMetaDataSchema.optional(),
    hidden: AssessmentOnboardingHiddenSchema,
    calculated: z.object({}),
    answers: z.array(TypeformAnswerSchema),
});

export const TypeformEndingSchema = z.object({
    id: z.string(),
    ref: z.string(),
    title: z.string().optional(),
    type: z.string().optional(),
    properties: z.object({
        redirect_url: z.string().url().optional()
    }).optional()
})

export const TypeformWebHookDefinitionSchema = z.object({
    id: z.string(),
    title: z.string(),
    fields: z.array(TypeformFieldSchema),
    endings: z.array(TypeformEndingSchema),
})

export const TypeformWebHookResponseSchema = z.object({
    form_id: z.string(),
    token: z.string(),
    landed_at: z.string(),
    submitted_at: z.string(),
    hidden: AssessmentOnboardingHiddenSchema,
    definition: TypeformWebHookDefinitionSchema,
    answers: z.array(TypeformAnswerSchema),
    ending: TypeformEndingSchema
})

export const TypeformWebHookPayloadBodySchema = z.object({
    event_id: z.string(),
    event_type: z.string(),
    form_response: TypeformWebHookResponseSchema
})

export const BaseWebHookPayloadSchema = z.object({
    url: z.string().url(),
    headers: z.array(z.tuple([z.string(),z.string()])),
});
export const TypeformWebHookPayloadSchema = z.object({
    body: TypeformWebHookPayloadBodySchema
}).merge(BaseWebHookPayloadSchema);
export type TypeFormPayload = z.infer<typeof TypeformWebHookPayloadSchema>

export const UtilityApiWebhookEventSchema = z.object({
    type: z.enum([
        'authorization_created',
        'authorization_declined',
        'authorization_renewed',
        'authorization_update_started',
        'authorization_update_delayed',
        'authorization_update_delay',
        'authorization_update_retrying',
        'authorization_update_progress',
        'authorization_update_finished_successful',
        'authorization_update_finished_errored',
        'authorization_expired',
        'authorization_revoked',
        'meter_created',
        'meter_bills_added',
        'meter_intervals_added',
        'meter_historical_collection_started',
        'meter_historical_collection_delayed',
        'meter_historical_collection_retrying',
        'meter_historical_collection_progress',
        'meter_historical_collection_finished_successful',
        'meter_historical_collection_finished_errored'
    ]),
    authorization_uid: z.string(),
    meter_uid: z.string().optional()
})
export type UtilityApiWebHookEvent = z.infer<typeof UtilityApiWebhookEventSchema>
export const UtilityApiWebHookPayloadBodySchema = z.object({
    events: z.array(UtilityApiWebhookEventSchema)
})
export const UtilityApiWebHookPayloadSchema = z.object({
    body: UtilityApiWebHookPayloadBodySchema
}).merge(BaseWebHookPayloadSchema);
export type UtilityApiWebHookPayload = z.infer<typeof UtilityApiWebHookPayloadSchema>

export const UtilityApiMeterSchema = z.object({
    uid: z.string(),
    authorization_uid: z.string(),
    utility: z.string(),
    user_email: z.string().email(),
    base: z.object({
        meter_numbers: z.array(z.string()),
        service_address: z.string(),
        service_class: z.string(),
    })

})
export type UtilityApiMeter = z.infer<typeof UtilityApiMeterSchema>
export const UtilityApiListMetersSchema = z.object({
    meters: z.array(UtilityApiMeterSchema),
    next: z.string().url().nullish()
})
export const UtilityApiAuthorizationSchema = z.object({
    uid: z.string(),
    customer_email: z.string().email(),
    referrals: z.array(z.string()),
    notes: z.array(z.object({
        msg: z.string(),
        ts: z.string().nullish(),
        type: z.string().nullish()
    })),
    nickname: z.string().nullish(),
    revoked: z.string().nullish(),
    status: z.enum(["pending", "updated", "errored"]),
    status_message: z.string(),
    utility: z.string() //https://utilityapi.com/docs/utilities#utility-id
})
export type UtilityApiAuthorization = z.infer<typeof UtilityApiAuthorizationSchema>
export const UtilityApiBillSchema = z.object({
    authorization_uid: z.string(),
    base: z.object({
        bill_end_date: z.string(),
        bill_start_date: z.string(),
        bill_total_volume: z.number(),
        bill_total_unit: z.string(),
        bill_total_cost: z.number(),
        meter_numbers: z.array(z.string()),
        service_address: z.string(),
        service_class: z.string(),
        service_identifier: z.string(),
        utility: z.string().nullish(),
        meter_uid: z.string().nullish(),
    })
})
export const UtilityApiListBillsResponseSchema = z.object({
    bills: z.array(UtilityApiBillSchema),
    next: z.string().url().nullish()
})
export type UtilityApiBill = z.infer<typeof UtilityApiBillSchema>


export const HubspotObjectTypeIdEnumSchema = z.enum([
        "0-1",//Contacts
        // "0-2",//Companies //https://developers.hubspot.com/docs/api/crm/understanding-the-crm#object-type-ids
        "0-3",//Deals
        // "0-5",//Tickets
        "0-7",//Products
        "0-8",//Line Items
        "0-14",//Quotes
        // "0-52",//Invoices
        "0-47",//Meetings
    ])
export type HubspotObjectTypeIdEnum = z.infer<typeof HubspotObjectTypeIdEnumSchema>
export const HubspotWebhookEventBaseSchema = z.object({
    objectId: z.coerce.string(),
    objectTypeId: HubspotObjectTypeIdEnumSchema,
    eventId: z.number(),
    subscriptionId: z.number(),
    portalId: z.number(),
    appId: z.number(),
    occurredAt: z.number(),
    attemptNumber: z.number(),
    changeSource: z.string(),
    // isSensitive: z.boolean(),
    // sourceId: z.string(),
})
export const HubspotWebhookEventSchema = z.discriminatedUnion('subscriptionType', [
    z.object({
        subscriptionType: z.literal("object.propertyChange"),
        propertyName: z.string(),
        propertyValue: z.string()
    }).merge(HubspotWebhookEventBaseSchema),
    z.object({
        subscriptionType: z.literal("object.creation"),
    }).merge(HubspotWebhookEventBaseSchema),
    z.object({
        subscriptionType: z.literal("object.merge"),
        mergedObjectIds: z.array(z.coerce.string())
    }).merge(HubspotWebhookEventBaseSchema),
    z.object({
        subscriptionType: z.literal("object.deletion"),
    }).merge(HubspotWebhookEventBaseSchema)])
export const HubspotWebHookPayloadBodySchema = z.array(HubspotWebhookEventSchema)
export type HubspotWebhookEvent = z.infer<typeof HubspotWebhookEventSchema>
export const HubspotWebHookPayloadSchema = z.object({
    body: HubspotWebHookPayloadBodySchema
}).merge(BaseWebHookPayloadSchema);
export type HubspotWebHookPayload = z.infer<typeof HubspotWebHookPayloadSchema>


export const AutodeskDerivativeWebhookPayloadBodySchema = z.object({
    payload:z.object({
        URN:z.string(),
        EventType:z.string(),
        Payload:z.object({
            status:z.string()
        })
    })
})
export const AutodeskDerivativeWebhookPayloadSchema = z.object({
    body: AutodeskDerivativeWebhookPayloadBodySchema
}).merge(BaseWebHookPayloadSchema);
export type AutodeskDerivativeWebhookPayload = z.infer<typeof AutodeskDerivativeWebhookPayloadSchema>
