import {Loader, Menu, Modal, TextInput, UnstyledButton} from "@mantine/core";
import {IconDots} from "@tabler/icons-react";
import {modals} from "@mantine/modals";
import React, {useState} from "react";
import {HStack, VStack} from './DesignBase.js';
import {DesignedButton} from './DesignComponents.js';

export function DeleteWidget({deleteTitle, name, disabled, deleteFn, renameTitle, renameFn}: {
    deleteTitle: string,
    name: string,
    disabled?: boolean,
    deleteFn: () => void,
    renameTitle?: string,
    renameFn?: (name: string) => void
}) {
    const [newName, setNewName] = useState(name)
    const [renameOpen, setRenameOpen] = useState(false)
    const [working, setWorking] = useState(false)
    return <Menu>
        <Modal opened={renameOpen} onClose={() => setRenameOpen(false)}>
            {renameFn &&
                <VStack>
                    <TextInput
                        value={newName}
                        label={"New Name"}
                        onChange={(e) => {
                            console.log(`change: ${e.currentTarget.value}`)
                            setNewName(e.currentTarget.value)
                        }}
                    />
                    <HStack>
                        <DesignedButton inverse onClick={modals.closeAll}>Cancel</DesignedButton>
                        <DesignedButton disabled={working} onClick={() => {
                            setWorking(true)
                            renameFn(newName)
                            setRenameOpen(false)
                        }}>{working ? <Loader/> : "Rename"}</DesignedButton>
                    </HStack>
                </VStack>
            }
        </Modal>
        <Menu.Target>
            <UnstyledButton>
                <IconDots size="1.5rem"/>
            </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
            {renameTitle && renameFn && <Menu.Item
                disabled={disabled}
                onClick={() => setRenameOpen(true)}
            >
                {renameTitle}
            </Menu.Item>
            }
            <Menu.Item
                disabled={disabled}
                onClick={() =>
                    modals.openConfirmModal({
                        title: deleteTitle,
                        children: (
                            <div>
                                Are you sure you want to delete <br/>
                                <strong>{name}</strong>?
                            </div>
                        ),
                        labels: {confirm: 'Confirm', cancel: 'Cancel'},
                        onConfirm: () => {
                            deleteFn()
                        },
                    })
                }
            >
                {deleteTitle}
            </Menu.Item>
        </Menu.Dropdown>
    </Menu>
}