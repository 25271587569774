import {Table} from "@mantine/core";
import {HomeDetailsSchema, UserAggregate} from "@seeair/schemas";
import {buildShortAddress, getLatestAssessment} from "@seeair/shared";
import {Link} from "@tanstack/react-router";
import dayjs from "dayjs";
import React from "react";

export function AdminUserTable({users}:{users:Array<UserAggregate>}) {
    return <Table striped highlightOnHover withTableBorder>
        <Table.Thead>
            <Table.Tr>
                <Table.Th>Email</Table.Th>
                <Table.Th>Address</Table.Th>
                <Table.Th>Status</Table.Th>
                <Table.Th>Admin Page Link</Table.Th>
                <Table.Th>Homeowner Page</Table.Th>
                <Table.Th>Home Details<br/>(of {Object.keys(HomeDetailsSchema.shape).length})</Table.Th>
                <Table.Th>Files</Table.Th>
                <Table.Th>Recs<br/>| Hidden</Table.Th>
                <Table.Th>Projects</Table.Th>
                <Table.Th>Modified</Table.Th>
            </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
            {users.flatMap(u => u.homes).map(h => {
                const user = users.find(u => u.homes.find(uh => uh.home_id == h.home_id))
                const latestAssessment = getLatestAssessment(h.assessments)

                return <Table.Tr key={h.home_id}>
                    <Table.Td>{user?.email}</Table.Td>
                    <Table.Td>{buildShortAddress(h.address)}</Table.Td>
                    <Table.Td>{latestAssessment?.assessment_status}</Table.Td>
                    <Table.Td>
                        <Link
                            to="/admin/assessment/$assessment_id"
                            search={{tab: 'home_details'}}
                            params={{
                                assessment_id: latestAssessment!.assessment_id
                            }}
                        >Admin</Link>
                    </Table.Td>
                    <Table.Td>
                        <Link
                            to="/homes/$home_id"
                            params={{
                                home_id: h.home_id
                            }}
                        >Homeowner</Link>
                    </Table.Td>
                    <Table.Td>{Object.values(latestAssessment?.home_details || {}).filter(o => o).length}</Table.Td>
                    <Table.Td>{Object.keys(latestAssessment?.assessment_files || {}).length}</Table.Td>
                    <Table.Td>{h.recommendations.filter(r => !r.hidden).length} | {h.recommendations.filter(r => r.hidden).length}</Table.Td>
                    <Table.Td>{h.projects.length}</Table.Td>
                    <Table.Td>{dayjs(h.aggregate_last_modified).format()}</Table.Td>
                </Table.Tr>
            })
            }
        </Table.Tbody>
    </Table>
}