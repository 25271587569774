import dayjs from 'dayjs'
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts'
import {ElectrificationData} from "@seeair/schemas";
import React from 'react'

interface RenderConfig {
    mobile?: boolean
    width: number
    height: number
    energy_chart: ElectrificationData['energy_chart']
    show:'usage'|'cost'
}

export function EnergyChart({energy_chart, mobile, height, width,show}: RenderConfig) {
    const data = energy_chart
        .sort((a, b) => {
            return dayjs(a.Date).unix() - dayjs(b.Date).unix()
        })
        .slice(0, 12)
        .map((row) => ({
            name: dayjs(row.Date).format('MMM'),
            Baseload: Math.round(show == 'usage' ? row.Baseload_Usage : row.Baseload_Cost!),
            Heating: Math.round(show == 'usage' ? row.Heating_Usage : row.Heating_Cost!),
            Cooling: Math.round(show == 'usage' ? row.Cooling_Usage : row.Cooling_Cost!),
        }))

    return (
        <ResponsiveContainer width={'100%'} height={350}>
            <BarChart
                data={data}
                stackOffset="sign"
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="50000" vertical={false}/>
                <XAxis dataKey="name" includeHidden/>
                <YAxis padding={{bottom: 1}} unit={show=="usage"?"kWh":"USD"}/>
                <Tooltip/>
                <Legend
                    layout={mobile ? "horizontal" : "vertical"}
                    align={mobile ? "center" : "right"}
                    verticalAlign={mobile ? "top" : "middle"}
                    wrapperStyle={{paddingLeft: 10}}
                />
                {/* <ReferenceLine y={0} stroke="#000" /> */}
                <Bar
                    dataKey="Baseload"
                    fill="#E3DE03"
                    barSize={25}
                    isAnimationActive={false}
                    stackId="stack"
                />
                <Bar
                    dataKey="Heating"
                    fill="#DC9540"
                    barSize={25}
                    isAnimationActive={false}
                    stackId="stack"
                />
                <Bar
                    dataKey="Cooling"
                    fill="#59BBFD"
                    barSize={25}
                    isAnimationActive={false}
                    stackId="stack"
                />
            </BarChart>
        </ResponsiveContainer>
    )
}