import {createFileRoute} from "@tanstack/react-router";
import {SheetsDataProvider, UserLoggedInRedirectToSignInCheck} from "@seeair/shared-components";
import { trpc} from "~/lib-client";
import React from "react";
import {RecommendationsPage} from "@seeair/homeowner-components";

export const Route = createFileRoute('/webview/home/$home_id/recommendations')({
    component: function RecommendationsWebView() {
        const {home_id} = Route.useParams()
        const [home] = trpc.HOMEOWNER.getHomeAggregate.useSuspenseQuery({home_id})

        return <UserLoggedInRedirectToSignInCheck>
            <SheetsDataProvider>
                <RecommendationsPage home={home}/>
            </SheetsDataProvider>
        </UserLoggedInRedirectToSignInCheck>
    }

})