import {createFileRoute, useNavigate} from '@tanstack/react-router'
import { trpc} from '~/lib-client'
import {
	AcceptInvitePage,
	AcceptInviteSchema,
	AcceptInviteType
} from "@seeair/homeowner-components";
import {AbsoluteCenterStack, TextError} from "@seeair/shared-components";
import {Loader} from "@mantine/core";
import React,{useEffect} from "react";

export const Route = createFileRoute('/homes/_homes/accept-invite')({
	validateSearch: (data): AcceptInviteType | Record<string, never> => {
		return AcceptInviteSchema.parse(data)
	},
	component: function AcceptInvitePageRoute(){
		const {hid, code} = Route.useSearch()
		const {data:home,isLoading:homeIsLoading,error:homeError} = trpc.HOMEOWNER.getHomeAggregate.useQuery({home_id: hid})
		const {data:shareDetails,isLoading:shareIsLoading,error:shareError} = trpc.HOMEOWNER.getShareDetails.useQuery({hid, code})
		const navigate = useNavigate()
		useEffect(()=>{
			void (async () => {
				if(home) {
					await navigate({
						to: '/homes/$home_id',
						params: {home_id: home.home_id}
					})
				}
			})()

		},[home,navigate])
		if(homeIsLoading || shareIsLoading) {
			return <AbsoluteCenterStack><Loader /></AbsoluteCenterStack>
		} else if(shareError) {
			return <AbsoluteCenterStack><TextError>{shareError.message}</TextError></AbsoluteCenterStack>
		}
		return <AcceptInvitePage hid={hid} code={code} shareDetails={shareDetails!} />
	}
})