import {createFileRoute} from "@tanstack/react-router";
import React from 'react'

export const Route = createFileRoute('/533096132DC5/demo')({
    component: function DemoComponent() {
        return <div className="relative w-screen h-screen">
            <iframe src="https://app.supademo.com/embed/cm2dcasd915vorbxrxxce4wt0?embed_v=2" loading="lazy"
                    title="SeeAir Demo"
                    allow="clipboard-write"
                    allowFullScreen
                    className="absolute top-0 left-0 w-full h-full" />
        </div>
    }
})
