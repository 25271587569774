import {createFileRoute, Outlet} from '@tanstack/react-router'

import {
    Layout,
    SheetsDataProvider,
    AdminUserRedirectToSignInCheck
} from "@seeair/shared-components";
import {Helmet} from "react-helmet-async";
import React from "react";

export const Route = createFileRoute('/admin/_admin')({
    component: () => <SheetsDataProvider>
        <AdminUserRedirectToSignInCheck>
            <Layout>
                <Helmet>
                    <title>SeeAir Admin</title>
                    <meta name="description"
                          content="SeeAir Admin Dashboard"/>
                </Helmet>
                <Outlet/>
            </Layout>
        </AdminUserRedirectToSignInCheck>
    </SheetsDataProvider>
})