import {createFileRoute} from '@tanstack/react-router'
import { trpc} from '~/lib-client'
import { RecommendationsPage} from "@seeair/homeowner-components";
import React from "react";

export const Route = createFileRoute('/homes/_homes/$home_id/')({
	component: function HomeDashboardRoute() {
		const {home_id} = Route.useParams()
		const [home] = trpc.HOMEOWNER.getHomeAggregate.useSuspenseQuery({home_id})
		return <RecommendationsPage home={home}/>
	}
})
