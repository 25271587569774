// Custom assertion function
import {ZodType} from "zod";
import _buildJsep, {CoreExpression, Expression} from "jsep";
import {match} from "ts-pattern";
import dayjs from "dayjs";

export function withinOneOf(actual: number, expected: number, delta = 1) {
    return Math.abs(actual - expected) <= delta;
}

export function getFileExtension(fileName: string): string {
    const dotIndex = fileName.lastIndexOf('.')
    if (dotIndex < 0) {
        return ''
    } else {
        return fileName.slice(dotIndex + 1)
    }
}

export function validateArray<T>(array: Array<any>, zod: ZodType<T>, throwOnInvalidMessage?: string): Array<T> {
    const validatedData: Array<T> = []
    let invalidCount = 0
    for (const a of array) {
        const parseResult = zod.safeParse(a)
        if (!parseResult.success) {
            invalidCount++
            console.log(`validation error: ${JSON.stringify(parseResult.error)}`)
            console.log(parseResult.error)
        } else {
            validatedData.push(parseResult.data)
        }
    }
    if (throwOnInvalidMessage && invalidCount > 0) {
        throw `${throwOnInvalidMessage}: ${invalidCount} of ${array.length}  invalid`
    }
    return validatedData
}

// REGEX to build a string parser: (?<!\\){[\s\S]*?}
type Range = { color: string; simpleCondition: string }

export function getMatching(ranges: Array<Range>, val: number): string | undefined {
    const lookups = {val}
    for (const range of ranges) {
        const cond = mapSimple(range.simpleCondition)
        const res = getResult(_buildJsep(cond), lookups)
        if (res === true) {
            return range.color
        }
    }
}

export function getResult(cond: Expression, lookups: Record<string, any>): unknown {
    return (
        match(cond as CoreExpression)
            .with({type: 'Identifier'}, (expr) => {
                if (!(expr.name in lookups)) {
                    throw new Error(`[JSEP] Unknown symbol: ${expr.name}`)
                }
                return lookups[expr.name]
            })
            .with({type: 'Literal'}, (expr) => expr.value)
            .with({type: 'BinaryExpression'}, (expr) => {
                const left = getResult(expr.left, lookups)
                const right = getResult(expr.right, lookups)

                return match(expr.operator)
                    .with('&&', () => Boolean(left) && Boolean(right))
                    .with('||', () => Boolean(left) || Boolean(right))
                    .otherwise(() => {
                        // Numeric options
                        if (!isNumeric(left) || !isNumeric(right)) {
                            throw new Error(
                                `[JSEP] Left and Right operators must be numeric, got: [${typeof left}, ${typeof right}]`
                            )
                        }
                        return match(expr.operator)
                            .with('>', () => left > right)
                            .with('>=', () => left >= right)
                            .with('<', () => left < right)
                            .with('<=', () => left <= right)
                            .otherwise(() => {
                                throw new Error(`[JSEP] Unsupported operator: ${expr.operator}`)
                            })
                    })
            })
            // No
            // .with({type: 'MemberExpression'}, () => {})
            .otherwise(() => {
                throw new Error(`[JSEP] Unsupported expression type: ${cond.type}`)
            })
    )
}

/**
 * <val
 * >val
 * val-val
 * <val, >val, val-val
 */
export function mapSimple(str: string): string {
    return str
        .trim()
        .split(', ')
        .map((_part) => {
            const part = _part.trim()
            if (['>', '<'].includes(part[0] ?? '')) {
                return `val ${part[0]} ${part.slice(1)}`
            } else if (part.indexOf('-') > 0) {
                const [a, b] = part.split('-')
                if (!a || !b) {
                    throw new Error(`[JSEP] Error parsing "-" expression part: ${part}`)
                }
                return `val >= ${a.trim()} && val <= ${b.trim()}`
            } else {
                throw new Error(`[JSEP] Unsupported expression part: ${part}`)
            }
        })
        .map((part) => `(${part})`)
        .join(' || ')
}

export function truncateString(text: string, charMax: number) {
    if (text.length > charMax) {
        return `${text.slice(0, charMax)}...`
    }
    return text
}

export function shortDateFormat(date: string | number): string {
    return dayjs(date).format("ddd, MMM D YYYY")
}

const isNumeric = (x: unknown): x is number => typeof x === 'number'

export function formatNumber(n: number, maximumFractionDigits: number = 2): string {
    return n.toLocaleString(undefined, {maximumFractionDigits})
}

export function lastNChars(s: string, n: number): string {
    if (s.length <= n) {
        return s
    } else {
        return `...${s.slice(-1 * n)}`
    }
}

export function firstNChars(s: string, n: number): string {
    if (s.length <= n) {
        return s
    } else {
        return `${s.slice(n)}...`
    }
}

export function formatMoney(n: number): string {
    return n.toLocaleString(undefined, {maximumFractionDigits: 0, style: "currency", currency: "USD"})
}

export function formatPercentage(n: number): string {
    return `${(n * 100).toLocaleString(undefined, {maximumFractionDigits: 0})}%`
}

export function formatQuantity(n: number): string {
    return n.toLocaleString(undefined, {maximumFractionDigits: 1})
}

export function formatYears(n: number): string {
    if(n>3) {
        return `${Math.round(n)} years`
    } else {
        const years = Math.floor(n)
        const months = Math.floor((n-years)*12)
        const yearString = `${years} year${years>1?"s":""}`
        const monthString = `${months} month${years>1?"s":""}`
        if(months > 0 && years > 0) {
            return `${yearString}, ${monthString}`
        } else if(months == 0 && years > 0) {
            return yearString
        } else {
            return monthString
        }
    }
}
export function genHex(len:number):string {
    return Math.floor(Math.random() * Math.pow(16,len)).toString(16).padStart(len, '0')
}
export const genId = (type: string) => `${type}_${genHex(8)}-${genHex(4)}-${genHex(4)}-${genHex(4)}-${genHex(12)}`

export const genShortId = (type: string) => `${type}_${genHex(4)}`
