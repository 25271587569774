import {createFileRoute} from '@tanstack/react-router'

import {z} from 'zod'
import {AppRedirect} from "@seeair/homeowner-components";
import {useQuery} from "@tanstack/react-query";
import {SuspenseLoader, UserLoggedInRedirectToSignInCheck} from "@seeair/shared-components";
import {Helmet} from "react-helmet-async";
import React from "react";

const tokenResponseSchema = z.object({token: z.string()})
export const Route = createFileRoute('/webview/app-redirect')({
    component: function AppRedirectComponent() {
        const {data: url} = useQuery({
            queryKey: ["auth", "token"],
            queryFn: async () => {
                const res = await fetch('/auth/token')
                if (res.ok) {
                    const tokenResponse = tokenResponseSchema.parse(await (res).json())
                    return `com.seeair.healthyhome://seeair.com/login?${new URLSearchParams(tokenResponse)}`
                } else {
                    return null
                }
            }
        })
        if (!url) {
            return SuspenseLoader
        }
        return <UserLoggedInRedirectToSignInCheck>
            <Helmet>
                <title>SeeAir Login</title>
                <meta name="description"
                      content="SeeAir Login"/>
            </Helmet>
            <AppRedirect url={url}/>
        </UserLoggedInRedirectToSignInCheck>
    },
    validateSearch: z.object({redirect: z.string().optional()})
})


