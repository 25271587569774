import {HomeDetails, LocationId} from "@seeair/schemas";
import {Dayjs} from "dayjs";


const primaryGasHeaters: Array<HomeDetails["primary_heating"]> = ['Central gas furnace', 'Gas boiler']
const secondaryGasHeaters: Array<HomeDetails["secondary_heating"]> = ['Central gas furnace', 'Gas boiler', 'Room (through-the-wall) gas furnace']
const gasWaterHeaters: Array<HomeDetails["water_heater_type"]> = ["Tank-less Natural Gas Water Heater", "Tank Natural Gas Water Heater"]
export function hasGasStove(details: HomeDetails): boolean {
    return details.stove =="Gas" || details.stove_secondary == "Gas" || details.stove_tertiary == "Induction"
}
export function hasGasWaterHeater(details: HomeDetails): boolean {
    return gasWaterHeaters.includes(details.water_heater_type)
}
export function hasPrimaryGasHeat(details: HomeDetails): boolean {
    return primaryGasHeaters.includes(details.primary_heating)
}
export function hasPrimaryCooling(details: HomeDetails): boolean {
    return details.primary_cooling != 'None'
}
export function requiresGasInput(details: HomeDetails): boolean {
    if (!details.stove || !details.primary_heating) {
        return true //we don't know yet, they need to fill in more details
    }
    if(hasGasStove(details)) {
        return true
    }
    if (hasPrimaryGasHeat(details)) {
        return true
    }
    if (secondaryGasHeaters.includes(details.secondary_heating)) {
        return true
    }
    return false
}


export function isWinterMonth(d:Dayjs,location_id:LocationId): boolean{
    const month = d.month()+1
    return month < 5 || month > 9
}