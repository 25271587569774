import {
    ProjectAggregate,
} from "@seeair/schemas";
import {
    Badge,
    HStack,
    Proforma,
    ProjectIcon, ProjectIcons,
    TextBase,
    TextLg,
    TextLink,
    TextRLg,
    VStack
} from "@seeair/shared-components";
import React, {useState} from "react";
import {Accordion, Table} from "@mantine/core";
import {lineItemsToProformaData} from "@seeair/shared";

const HS_DEAL_DONE = 'completed'
const HS_PAYMENT_AWAITING = 'PENDING'
const HS_PAYMENT_PAID = 'PAID'
const HS_QUOTED = ['APPROVED', 'APPROVAL_NOT_NEEDED']

type ProjectStage = 'drafting' | 'quoted' | 'paid' | 'done'

function getProjectStage(project: ProjectAggregate): ProjectStage {
    if (project.hubspot_deal_stage == HS_DEAL_DONE) {
        return "done"
    }
    if (project.hubspot_quote_payment_status == HS_PAYMENT_PAID) {
        return "paid"
    }
    if (project.hubspot_quote_amount != null) {
        return "quoted"
    }
    return 'drafting'

}

function getProjectStageString(stage: ProjectStage): string {
    switch (stage) {
        case "drafting":
            return "Drafting"
        case "quoted":
            return "Quoted"
        default:
            return "Done"
    }
}

export function ProjectsAccordion({projects}: { projects: Array<ProjectAggregate> }) {
    const sortedProjects = [...projects]
        .sort((a, b) => {
            const titleA = a.project_title?.toUpperCase() ?? ""
            const titleB = b.project_title?.toUpperCase() ?? ""
            return titleA < titleB ? -1 : titleB < titleA ? 1 : 0
        })
    const [selected, setSelected] = useState(sortedProjects[0]?.project_id ?? null)
    return <VStack classNames="my-4">
        <Accordion maw={"100%"} variant="separated" radius="xl" value={selected} onChange={setSelected}>
            {sortedProjects
                .map(p => {
                    return <Accordion.Item key={p.project_id}
                                           value={p.project_id}
                                           className="border-primary-light-blue mx-4">
                        <Accordion.Control icon={<ProjectIcons rec_numbers={p.recommendations.map(r=>r.original_rec_id)} lg />}>
                            <ProjectPanelHeader project={p}/>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <ProjectPanel project={p}/>
                        </Accordion.Panel>

                    </Accordion.Item>
                })}
        </Accordion>
    </VStack>
}

function ProjectPanelHeader({project}: { project: ProjectAggregate }) {
    const stage = getProjectStage(project)
    return <HStack leftCenter>
        <div className="flex-shrink flex-grow-0">
            <Badge blue={stage == 'quoted'}
                   green={stage == 'done'}
                   gray={stage == 'drafting'}
            >
                <b>Status</b> {getProjectStageString(stage)}
            </Badge>
        </div>
        <TextRLg center block wFull classNames="flex-grow flex-shrink capitalize">{project.project_title}</TextRLg>
    </HStack>
}


export function ProjectPanel({project}: { project: ProjectAggregate }) {
    const stage = getProjectStage(project)
    return <VStack>
        <VStack classNames="px-16">
            <div className="p-16 pt-4">
                <Table>
                    <Table.Tbody>
                        {project.recommendations.map(r => <Table.Tr key={r.recommendation_id}>
                            <Table.Td><ProjectIcon rec_number={r.original_rec_id} /></Table.Td>
                            <Table.Td><TextLg m0 classNames="inline-block w-64 px-4">{r.title}</TextLg></Table.Td>
                            <Table.Td><TextBase>{r.description}</TextBase></Table.Td>
                        </Table.Tr>)}
                    </Table.Tbody>
                </Table>
            </div>
        </VStack>
        {stage == 'drafting'
            ? <TextBase classNames="px-16">We are still drafting our proposal of work</TextBase>
            : <VStack center>
                <Proforma title="Quoted Price"
                          data={lineItemsToProformaData(
                              project.hubspot_quote_line_items ?? [],
                              project.recommendations)}/>
                {project.hubspot_quote_url &&
                    <a href={project.hubspot_quote_url} target="_blank" rel="noreferrer">
                        <TextLink>View Quote, Sign & Pay</TextLink>
                    </a>}
            </VStack>
        }
    </VStack>
}