import Viewer3DConfig = Autodesk.Viewing.Viewer3DConfig;

export async function initViewer(
    initializerOptions:Autodesk.Viewing.InitializerOptions,
    htmlElement:HTMLElement,
    viewerConfig:Viewer3DConfig
    ):Promise<Autodesk.Viewing.GuiViewer3D>{
    return new Promise((resolve, reject)=> {
        Autodesk.Viewing.Initializer(initializerOptions, () => {
            const viewer = new Autodesk.Viewing.GuiViewer3D(htmlElement, viewerConfig);
            const startedCode = viewer.start();
            if (startedCode > 0) {
                reject({code: startedCode, message: 'Failed to create a Viewer: WebGL not supported.'})
            } else {
                resolve(viewer)
            }
        })
    })
}
export async function loadDocument(viewer:Viewer3DConfig,urn:string):Promise<Autodesk.Viewing.Model> {
    return new Promise((resolve, reject) => {
        Autodesk.Viewing.Document.load(
            `urn:${btoa(urn)}`,
            async (doc) => {
                const model = await viewer.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry())
                resolve(model)
            },
            (errorCode, errorMsg, messages) => {
                reject({code: errorCode, message:errorMsg})
            })
    })
}
export type SeeAirViewerNode = { name: string, id: number,recNum:number }
export async function loadSeeAirRecs(model:Autodesk.Viewing.Model):Promise<Array<SeeAirViewerNode>> {
    const nodeCollector: Array<SeeAirViewerNode> = []
    const tree:Autodesk.Viewing.InstanceTree = await new Promise((resolve, reject) => {
        model.getObjectTree((tree)=>{
            resolve(tree)
        },(err)=>{
            reject(err)
        })
    })
    await getAllChildren(model,tree,tree.getRootId(),nodeCollector)
    return nodeCollector
}
async function getAllChildren(model:Autodesk.Viewing.Model,tree: Autodesk.Viewing.InstanceTree,id:number,collector:Array<SeeAirViewerNode>) {
    const children = (await getChildren(model,tree,id))
    collector.push(...children)
    await Promise.all(children.map(c=>getAllChildren(model,tree,c.id,collector)))
}
async function getChildren(model:Autodesk.Viewing.Model,tree: Autodesk.Viewing.InstanceTree,id:number):Promise<Array<SeeAirViewerNode>>{
    const propPromises:Array<Promise<SeeAirViewerNode>> = []
    let count = tree.getChildCount(id)
    if(count == 0){
        return []
    }
    await new Promise((resolve, reject) => {
        tree.enumNodeChildren(id, (cid) =>{
            propPromises.push(new Promise((resolve1,reject1)=>{
                model.getProperties(cid,(props)=>{
                    const matchingProperty = props.properties.find(p=>p.attributeName=='SEEAIR_RecommendationId')
                    const recNum = parseInt(matchingProperty?.displayValue as string ?? "0")
                    resolve1({id:cid,name:tree.getNodeName(cid),recNum})
                },(err)=>{
                    reject1(err)
                })
            }))
            count--
            if(count == 0){
                resolve(0)
            }
        },false)
    })
    return await Promise.all(propPromises)
}