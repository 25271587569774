import {RHideStack, VStack} from './DesignBase.js';
import React, {useState} from "react";
import {Viewer} from './Viewer.js';
import {HomeAggregate} from "@seeair/schemas";
import {RecommendationsAccordion} from './RecommendationsAccordion.js';

export function DigitalTwin({home, urn}: { home: HomeAggregate, urn: string }) {
    const [selectedRecs, setSelectedRecs] = useState([] as Array<number>)
    return <RHideStack xl reverse>
        <Viewer
            urn={urn}
            onRecToggleChange={(selectedRecNums) => {
                setSelectedRecs(selectedRecNums)
            }}
            recs={home.recommendations}
            className="h-screen w-screen xl:w-2/3vw"/> {/*this should always be visible*/}
        <VStack classNames="max-w-1/3 min-w-1/3 h-screen bg-slate-200 w-1/3vw max-h-screen overflow-auto"
                style={{width: "33.333333vw"}}>
            <RecommendationsAccordion
                recs={home.recommendations}
                projects={home.projects}
                filteredRecs={selectedRecs}
                splitView={true}
            /> {/*this should be hidden unless on xl screen*/}
        </VStack>

    </RHideStack>
}