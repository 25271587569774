import {
    AbsoluteCenterStack,
    DesignedButton,
    DigitalTwin,
    EnergyChart,
    HDivider,
    HStack,
    RecommendationsAccordion,
    ShareButton,
    SharePanel,
    Text3Xl,
    Text4Xl,
    TextBase,
    TextError,
    TextLg,
    VStack
} from "@seeair/shared-components";
import React, {useState} from "react";
import {
    AssessmentFile,
    HomeAggregate
} from "@seeair/schemas";
import {CloseButton, Loader, Modal, SegmentedControl, Tabs} from "@mantine/core";
import {trpc} from "~/lib-client";
import {ProjectsAccordion} from './ProjectsAccordion.js';
import {IconHammer, IconSparkles} from "@tabler/icons-react";
import {buildShortAddress, formatMoney, formatNumber, getLatestFile, getLatestFinishedAssessment} from "@seeair/shared";

type Show = 'usage' | 'cost'

export function RecommendationsPage({home}: { home: HomeAggregate }) {
    // const {data:threeDThumbnailSrc,mutate:load3dThumb} = trpc.HOMEOWNER.getAssessmentFileDownloadUrl.useMutation()
    const [currentModal, setCurrentModal] = useState<'share'>()

    const latestAssessment = getLatestFinishedAssessment(home)
    let electrification, latestRenderingFile
    if (latestAssessment != 'not_found') {
        latestRenderingFile = getLatestFile(latestAssessment, 'capture_rendering_enhanced')
        // console.log(`lates file: ${JSON.stringify(latestRenderingFile)}`)
        electrification = latestAssessment.assessment_data?.electrification
    }
    const costGenerated = electrification?.energy_chart?.some(o => o.Heating_Cost != null)
    const [show, setShow] = useState(costGenerated ? 'cost' : 'usage' as Show)
    let electricAnnual, gasAnnual
    if (latestAssessment != 'not_found' && electrification) {
        if (show == "usage") {
            electricAnnual = `${formatNumber(electrification.energy_use.Electric, 0)} kWh`
            gasAnnual = `${formatNumber(electrification.energy_use.Gas, 0)}M BTU`
        } else {
            electricAnnual = `${formatMoney(electrification.energy_cost.Electric)}`
            gasAnnual = `${formatMoney(electrification.energy_cost.Gas)}`
        }
    }
    // useEffect(() => {
    //     if (latestAssessment != 'not_found') {
    //         load3dThumb({home_id:home.home_id,assessment_id:latestAssessment.assessment_id,file_enum:'capture_rendering_enhanced.thumbnail'})
    //     }
    // }, [latestAssessment,load3dThumb,home]);
    return <VStack classNames="flex-grow bg-white">
        <HStack center>
            <Text4Xl>{buildShortAddress(home.address)}</Text4Xl>
            {(home.permissions?.has('owner') ?? false) && (
                <ShareButton onClick={() => setCurrentModal('share')}/>
            )}
        </HStack>
        <VStack center>
            <HStack center>
                <Text3Xl light center>Heating & Cooling Energy Costs</Text3Xl>
                {costGenerated && <SegmentedControl className="ml-8" value={show} onChange={(v) => setShow(v as Show)}
                                                    data={[{label: "Cost", value: 'cost'}, {
                                                        label: "Usage",
                                                        value: 'usage'
                                                    }]}/>}
            </HStack>
            {
                electrification
                    ? <React.Fragment>
                        <HStack baseline center>
                            <TextLg classNames="pr-4">Annual Electric Usage:</TextLg>
                            <TextLg light>{electricAnnual}</TextLg>
                            <TextLg classNames="pr-4 pl-8">
                                Annual Gas Usage:&nbsp;
                            </TextLg>
                            <TextLg light>{gasAnnual}</TextLg>

                        </HStack>
                        <EnergyChart
                            mobile
                            width={850}
                            height={350}
                            energy_chart={electrification.energy_chart}
                            show={show}
                        />
                    </React.Fragment>
                    : <TextBase>Electrification Data Missing</TextBase>
            }
        </VStack>
        <HDivider/>
        <VStack center>
            <Text3Xl light center>Digital Twin</Text3Xl>

            {
                latestRenderingFile && latestRenderingFile != 'not_found'
                    ? <DigitalTwinSection file={latestRenderingFile} home={home}/>
                    : <DigitalTwinNotReady/>
            }
        </VStack>

        <HDivider/>
        <Text3Xl light center>Home Improvement Plan</Text3Xl>
        <Tabs defaultValue="recommendations">
            <Tabs.List classNames={{list: "justify-center"}}>
                <Tabs.Tab value="recommendations" leftSection={<IconSparkles/>}>
                    Recommendations
                </Tabs.Tab>
                <Tabs.Tab value="projects" leftSection={<IconHammer/>}>
                    Projects
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="recommendations">
                <VStack center>
                    <VStack classNames="max-w-screen-xl">
                        {
                            home.recommendations.length == 0
                                ?
                                <TextBase>Your home is healthy and energy efficient, thanks for doing your part to save
                                    the
                                    world!</TextBase>
                                : <RecommendationsAccordion
                                    recs={home.recommendations}
                                    projects={home.projects}
                                    filteredRecs={[]}
                                    splitView={false}
                                />
                        }
                    </VStack>
                </VStack>
            </Tabs.Panel>
            <Tabs.Panel value="projects">
                <VStack center>
                    <VStack classNames="max-w-screen-xl">
                        {
                            home.recommendations.length == 0
                                ? <TextBase>We have no planned projects yet, please contact us to get started</TextBase>
                                : <ProjectsAccordion projects={home.projects}/>
                        }
                    </VStack>
                </VStack>
            </Tabs.Panel>
        </Tabs>
        <Modal title="Shares" opened={currentModal == 'share'} onClose={() => setCurrentModal(undefined)}>
            <SharePanel home={home}/>
        </Modal>
    </VStack>
}

function DigitalTwinNotReady() {
    return <TextBase>Model Not Yet Ready</TextBase>
}

function DigitalTwinSection({file, home}: { file: AssessmentFile, home: HomeAggregate }) {
    const [showViewer, setShowViewer] = useState(false)
    const {
        data: urnResponse,
        error: urnError,
        isPending: urnPending
    } = trpc.HOMEOWNER.getConversionInfoForAssessmentFile.useQuery({home_id: home.home_id, s3_url: file.s3_url})
    if (urnPending) {
        return <VStack relative><AbsoluteCenterStack><Loader/></AbsoluteCenterStack></VStack>
    } else if (urnError) {
        return <VStack relative><AbsoluteCenterStack><TextError>Error Loading Digital
            Twin: {urnError.message}</TextError></AbsoluteCenterStack></VStack>

    } else if (!urnResponse || !urnResponse.urn || !urnResponse.objectExists || urnResponse.conversionStatus != 'success') {
        return <DigitalTwinNotReady/>
    }
    return <VStack center>
        <Modal classNames={{body: "p-0"}} withCloseButton={false} fullScreen opened={showViewer}
               onClose={() => setShowViewer(false)}>
            <VStack>
                <CloseButton size="xl" className="absolute top-2 right-2 z-50" onClick={() => setShowViewer(false)}/>
                <DigitalTwin home={home} urn={urnResponse.urn}/>
            </VStack>
        </Modal>
        {/*{threeDThumbnailSrc && <img src={threeDThumbnailSrc.url} />}*/}
        <DesignedButton onClick={() => setShowViewer(true)}>Launch 3D Viewer</DesignedButton>
    </VStack>
}


