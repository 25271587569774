import {createTRPCReact} from '@trpc/react-query'
import {type AppRouter} from '@seeair/trpc'
import dayjs, {type Dayjs} from 'dayjs'
import SuperJSON from 'superjson'

export function initSuperJSON() {
	SuperJSON.registerCustom(
		{
			isApplicable: (v): v is Dayjs => dayjs.isDayjs(v),
			serialize: (v) => v.toISOString(),
			deserialize: (v) => dayjs(v),
		},
		'dayjs'
	)
}



export const trpc = createTRPCReact<AppRouter>()


