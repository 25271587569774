import {AdminTab, Assessment, Home, HomeAggregate} from "@seeair/schemas";
import {buildShortAddress} from "@seeair/shared";
import {trpc} from "~/lib-client";
import React from "react";
import {AssessmentNotesPanel} from './AssessmentNotesPanel.js';
import {AdminRecommendationsPanel} from './AdminRecommendationsPanel.js';
import {
    AssessmentFilesTable,
    COLOR_WHITE,
    DeleteWidget,
    DesignedButton,
    EditAddressPanel,
    EditMissingHomeDetailsPanel,
    EditUtilitiesPanel,
    FullWithDesignedSection,
    getUseMutationOpt,
    HSpacer,
    HStack,
    RStack,
    Text2Xl,
    Text4Xl,
    TextLink
} from "@seeair/shared-components";
import {Tabs} from "@mantine/core";
import {
    IconBulb,
    IconChecklist,
    IconFiles,
    IconHammer,
    IconJson,
    IconMap,
    IconNote, IconSpy,
    IconSun
} from "@tabler/icons-react";
import {AssessmentStatusWidget} from './AssessmentStatusWidget.js';
import {useNavigate} from "@tanstack/react-router";
import {AssessmentSolarPanel} from './AssessmentSolarPanel.js';
import {AdminProjectsPanel} from './AdminProjectsPanel.js';
import {AdminPermissionsPanel} from './AdminPermissionsPanel.js';
import {AssessmentHubspotLinkWidget} from './AssessmentHubspotLinkWidget.js';

export function AdminAssessmentPage({home, assessment, tab = "home_details"}: {
    home: HomeAggregate
    assessment: Assessment
    tab?: AdminTab
}) {
    const navigate = useNavigate()
    const {
        isPending: isAutomatedAssessmentRunning,
        mutate: runAutomatedAssessment
    } = trpc.ADMIN.runAutomatedAssessment.useMutation(getUseMutationOpt(trpc.useUtils()))

    return (
        <FullWithDesignedSection background={COLOR_WHITE}>
            <RStack xl>
                <HStack baseline>
                    <Text4Xl>{buildShortAddress(home.address)}</Text4Xl>
                    <Text2Xl classNames="mx-4">{assessment.assessment_label}</Text2Xl>
                </HStack>
                <a className="cursor-pointer mx-8" onClick={() =>
                    navigate({
                        to: "/homes/$home_id",
                        params: {
                            home_id: home.home_id
                        }
                    })}><TextLink>Homeowner View</TextLink></a>
                <HSpacer/>
                <DeleteHomeWidget home={home}/>
            </RStack>
            <RStack xl>

                <AssessmentStatusWidget assessment={assessment}/>
                <DesignedButton
                    onClick={() => runAutomatedAssessment({
                        home_id: home.home_id,
                        assessment_id: assessment.assessment_id
                    })}
                    disabled={isAutomatedAssessmentRunning || !['bim_team_analysis','internal_review','pending_homeowner_review'].includes(assessment.assessment_status ?? 'not_started')}
                >
                    {isAutomatedAssessmentRunning ? 'Processing...' : 'Run Automated Assessment'}
                </DesignedButton>
                <AssessmentHubspotLinkWidget assessment={assessment}/>
            </RStack>
            <Tabs defaultValue={tab} onChange={async (value) => {
                await navigate({search: {tab: value}})
            }}>
                <Tabs.List>
                    <Tabs.Tab value="address" leftSection={<IconMap/>}><Text2Xl>Address</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="home_details" leftSection={<IconJson/>}><Text2Xl>Home Details</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="assessment_files" leftSection={<IconFiles/>}><Text2Xl>Assessment
                        Files</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="utilities" leftSection={<IconBulb/>}><Text2Xl>Utilities</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="recommendations" leftSection={<IconChecklist/>}><Text2Xl>Recommendations</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="solar" leftSection={<IconSun/>}><Text2Xl>Solar</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="notes" leftSection={<IconNote/>}><Text2Xl>Notes</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="projects" leftSection={<IconHammer/>}><Text2Xl>Projects</Text2Xl></Tabs.Tab>
                    <Tabs.Tab value="permissions" leftSection={<IconSpy/>}><Text2Xl>Permissions</Text2Xl></Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="address">
                    <EditAddressPanel
                        home={home}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="home_details">
                    <EditMissingHomeDetailsPanel
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="assessment_files">
                    <AssessmentFilesTable
                        assessment={assessment}
                        home={home}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="utilities">
                    <EditUtilitiesPanel
                        home={home}
                        assessment={assessment}/>
                </Tabs.Panel>
                <Tabs.Panel value="notes">
                    <AssessmentNotesPanel
                        home={home}
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="solar">
                    <AssessmentSolarPanel
                        home={home}
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="recommendations">
                    <AdminRecommendationsPanel
                        home={home}
                        assessment={assessment}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="projects">
                    <AdminProjectsPanel
                        home={home}
                    />
                </Tabs.Panel>
                <Tabs.Panel value="permissions">
                    <AdminPermissionsPanel
                        home={home}
                    />
                </Tabs.Panel>
            </Tabs>
        </FullWithDesignedSection>
    )
}

export function DeleteHomeWidget({home}: { home: Home }) {
    const {mutate: deleteHome, isPending} = trpc.ADMIN.deleteHome.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <DeleteWidget
        deleteTitle="Delete Home"
        disabled={isPending}
        name={buildShortAddress(home.address)}
        deleteFn={() => {
            deleteHome({home_id: home.home_id})
        }}/>
}