import {
    COLOR_LIGHT_GREY,
    FullWithDesignedSection,
    PageTitle,
    RStack,
    TextError,
    VStack
} from "@seeair/shared-components";
import {BlogBox, BlogBoxSkeleton} from "./BlogBox.js";
import React, {ReactElement} from "react";
import {Helmet} from "react-helmet-async";
import {HubspotBlog} from "@seeair/schemas";

export function BlogIndexPage({blogs, error}: {
    blogs: Array<HubspotBlog> | undefined,
    error: { message: string } | undefined | null
}) {
    let components: Array<ReactElement>
    if (blogs) {
        components = blogs.map(b => <BlogBox key={b.id} blog={b} classNames="bg-white w-full xl:w-1/2"/>)
    } else if (error) {
        components = []
    } else {
        components = [<BlogBoxSkeleton key={1}/>, <BlogBoxSkeleton key={2}/>, <BlogBoxSkeleton key={3}/>, <BlogBoxSkeleton key={4}/>,
            <BlogBoxSkeleton key={5}/>, <BlogBoxSkeleton key={6}/>, <BlogBoxSkeleton key={7}/>]
    }
    const blogPairs: Array<[ReactElement, ReactElement?]> = []
    let unpaired: ReactElement | undefined = undefined
    for (const b of components) {
        if (unpaired == undefined) {
            unpaired = b
        } else {
            blogPairs.push([unpaired, b])
            unpaired = undefined
        }
    }
    if (unpaired != undefined) {
        blogPairs.push([unpaired, undefined])
    }
    return <FullWithDesignedSection background={COLOR_LIGHT_GREY}>
        <Helmet>
            <title>Blog</title>
            <meta name="description"
                  content="SeeAir provides an educational and thought provoking blog aimed at helping homeowners understand their home and its impact on health and the environment."/>
            <link rel="canonical" href="https://seeair.com/blog"/>
        </Helmet>
        <PageTitle text="Blog"/>
        <VStack center>
            {error && <TextError>{error.message}</TextError>}
            {
                blogPairs.map(([b1, b2]) => <RStack key={b1.key} xl classNames="gap-x-8">
                    {b1}
                    {b2
                        ? b2
                        : <div className="w-full xl:w-1/2"/>}
                </RStack>)
            }
        </VStack>
    </FullWithDesignedSection>

}