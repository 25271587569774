import { DesignedButton, Layout, Text2Xl, VStack} from "@seeair/shared-components";
import React from 'react'

export function AppRedirect({url}: { url: string}) {
    // const cookies  = parse(document.cookie)
    // const token = cookies["__Secure-authjs.session-token"]||cookies["authjs.session-token"]||"none"
    return <Layout>
        <VStack center classNames="flex-grow bg-white h-full">
            <DesignedButton
                onClick={() => {
                    if (url) {
                        window.open(url)
                    } else {
                        alert("Something went wrong, please try to log in again or contact support for assistance")
                    }
                }}>
                <Text2Xl>Launch SeeAir App</Text2Xl>
            </DesignedButton>

        </VStack>
    </Layout>

}
