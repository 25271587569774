import {createFileRoute} from '@tanstack/react-router'
import {AboutPage} from "@seeair/homeowner-components";
import {Helmet} from "react-helmet-async";
import React from "react";

export const Route = createFileRoute('/about')({
    component: function AboutPageComponent() {
        return <React.Fragment>
            <Helmet>
                <title>SeeAir</title>
                <meta name="description"
                      content="SeeAir specializes in no-cost home energy and health assessments and is a proud MassSave partner. Enhance your home's energy efficiency with our expert services."/>
                <link rel="canonical" href="https://seeair.com/about"/>
            </Helmet>
            <AboutPage/>
        </React.Fragment>
    }
})
