import {
    Assessment,
    AssessmentFile,
    AssessmentFiles,
    AssessmentFilesEnum,
    AssessmentFilesEnumSchema,
    FileUploadNonce,
    HomeAggregate,
} from "@seeair/schemas";
import React, {ReactElement, ReactNode, useEffect, useState} from "react";
import {
    Button,
    Combobox,
    FileInput,
    InputBase,
    Loader,
    Popover,
    Table,
    UnstyledButton,
    useCombobox
} from "@mantine/core";
import {trpc} from "~/lib-client";
import {
    IconCircleCheckFilled,
    IconDownload,
    IconExclamationCircle,
    IconFolderOpen,
    IconTrash
} from "@tabler/icons-react";
import {HStack, TextSm, TextXs, VStack} from "./DesignBase.js";
import {DesignedButton} from "./DesignComponents.js";
import classNames from "classnames";
import {useDisclosure} from "@mantine/hooks";
import {getUseMutationOpt} from './mutationHelper.js';
import {getFileVersionsSortedByLatest, lastNChars} from "@seeair/shared";

export function AssessmentFilesTable({assessment, home}: { assessment: Assessment, home: HomeAggregate }) {
    const [triggerDownloads, setTriggerDownload] = useState(false)
    const files = assessment.assessment_files || {} as AssessmentFiles
    const allPossibleFiles = Object.keys(AssessmentFilesEnumSchema.Values) as Array<AssessmentFilesEnum>
    return <VStack>
        <DesignedButton onClick={() => {
            setTriggerDownload(true)
        }}>Download All Files</DesignedButton>
        <Table striped highlightOnHover withTableBorder classNames={{tr: "min-h-24"}}>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>File</Table.Th>
                    <Table.Th>Count</Table.Th>
                    <Table.Th className="max-w-96 block">Version</Table.Th>
                    <Table.Th>Info</Table.Th>
                    <Table.Th>Download</Table.Th>
                    <Table.Th>Upload</Table.Th>
                    <Table.Th>Delete</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {allPossibleFiles.map((k) => <AssessmentFilesRow
                    triggerDownload={triggerDownloads}
                    key={k}
                    name={k}
                    fileVersions={files[k]}
                    assessment={assessment}
                    home={home}
                />)}
            </Table.Tbody>
        </Table>
    </VStack>
}

export function AssessmentFilesRow({triggerDownload, assessment, fileVersions, name, home}: {
    assessment: Assessment,
    home: HomeAggregate,
    fileVersions: Array<AssessmentFile> | undefined,
    name: keyof AssessmentFiles,
    triggerDownload: boolean
}) {
    const sortedFileVersions = getFileVersionsSortedByLatest(fileVersions ?? [])
    const [selectedFileVersion, setSelectedFileVersion] = useState(sortedFileVersions[0])
    const {
        mutate: deleteAll,
        isPending
    } = trpc.ADMIN.deleteAllAssessmentFileVersions.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <Table.Tr className="h-24">
        <Table.Td>{name}</Table.Td>
        <Table.Td>{fileVersions?.length ?? 0}</Table.Td>
        <Table.Td className="max-w-96 block overflow-hidden">
            <VStack>
                <AssessmentFileVersionSelectWidget
                    sortedFileVersions={sortedFileVersions}
                    selectedFileVersion={selectedFileVersion}
                    setSelectedFileVersion={setSelectedFileVersion}/>
                <TextXs>{selectedFileVersion?.s3_url}</TextXs>
            </VStack>
        </Table.Td>
        <Table.Td><AssessmentFilesInfoWidget home_id={home.home_id} fileEnum={name}
                                             selectedFileVersion={selectedFileVersion}/></Table.Td>
        <Table.Td><AssessmentFileDownloadWidget triggerDownload={triggerDownload}
                                                selectedFileVersion={selectedFileVersion}/></Table.Td>
        <Table.Td><AssessmentFileUploadWidget assessment_id={assessment.assessment_id} fileEnum={name}/></Table.Td>
        <Table.Td>
            <DesignedButton disabled={isPending || !selectedFileVersion} onClick={() => deleteAll({
                assessment_id: assessment.assessment_id,
                file_enum: name
            })}>
                {
                    isPending
                        ? <Loader size="sm"/>
                        : <React.Fragment><IconTrash/> All Versions</React.Fragment>
                }
            </DesignedButton></Table.Td>
    </Table.Tr>
}

const renderableFiles: Array<AssessmentFilesEnum> = ['capture_rendering_enhanced', 'capture_rendering_cleaned']
const cqeFiles: Array<AssessmentFilesEnum> = ['capture_rendered_floor_plan']

function AssessmentFilesInfoWidget({fileEnum, selectedFileVersion, home_id}: {
    home_id: string,
    fileEnum: AssessmentFilesEnum,
    selectedFileVersion?: AssessmentFile
}) {

    return <VStack>
        {selectedFileVersion && renderableFiles.includes(fileEnum) &&
            <HStack center>
                <TextSm classNames="mr-2">Renderable</TextSm>
                <RenderableFileInfoWidget selectedFileVersion={selectedFileVersion} home_id={home_id}/>
            </HStack>
        }
        {selectedFileVersion && cqeFiles.includes(fileEnum) &&
            <HStack center><TextSm classNames="mr-2">CQE</TextSm><CQEInfoWidget
                selectedFileVersion={selectedFileVersion}/></HStack>}
        {selectedFileVersion?.metadata &&
            <HStack center><TextSm classNames="mr-2">Metadata</TextSm><FileMetadataInfoWidget
                metadata={selectedFileVersion.metadata}/></HStack>}
    </VStack>
}

function tableFromJson(parsed: any): ReactElement {
    const data = Object.keys(parsed).reduce((acc, v) => {
        let component: any
        if (typeof parsed[v] == 'object') {
            component = tableFromJson(parsed[v])
        } else {
            component = JSON.stringify(parsed[v])
        }
        return [...acc, [v, component]]
    }, [] as Array<Array<ReactNode>>)
    return <Table className="text-white" data={{body: data}}/>
}

function FileMetadataInfoWidget({metadata}: { metadata: string }) {
    console.log(`metadata widget: ${metadata}`)
    const [opened, {toggle}] = useDisclosure(false);
    let icon
    let component
    let parsed: any
    try {
        parsed = JSON.parse(metadata)
    } catch {
    }
    if (!parsed) {
        icon = <IconExclamationCircle className="text-red-500"/>
        component = <TextSm inverse>There was an error parsing the metadata</TextSm>
    } else {
        icon = <IconCircleCheckFilled className="text-green-500"/>
        component = tableFromJson(parsed)
    }
    return <Popover position="bottom" shadow="md" opened={opened}>
        <Popover.Target>
            <UnstyledButton onClick={toggle}>
                {icon}
            </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown className="border-0 bg-black p-4 rounded-2xl">
            <VStack>
                {component}
            </VStack>
        </Popover.Dropdown>
    </Popover>
}

function CQEInfoWidget({selectedFileVersion}: { selectedFileVersion: AssessmentFile }) {
    const [opened, {toggle}] = useDisclosure(false);
    const {data, isPending} = trpc.ADMIN.getCQEInfo.useQuery()
    let icon
    let text
    let model_id: string | undefined
    let cqeUrl: string
    if (selectedFileVersion.metadata) {
        try {
            model_id = JSON.parse(selectedFileVersion.metadata).model_id
        } catch {
        }
        if (!model_id) {
            icon = <IconExclamationCircle className="text-red-500"/>
            text = "There was an error parsing the file metadata"
        } else {
            if (data) {
                const {api_key, config_id} = data
                cqeUrl = `https://cubitool.cubi.casa/?c=${config_id}&m=${model_id}&k=${api_key}&loc=en`
                return <IconFolderOpen className="text-green-500 cursor-pointer"
                                       onClick={() => window.open(cqeUrl, "_blank")}/>
            } else if (isPending) {
                icon = <IconExclamationCircle className="text-yellow-500"/>
                text = "Loading"
            } else {
                text = "Error loading config"
                icon = <IconExclamationCircle className="text-red-500"/>
            }
        }
    } else {
        icon = <IconExclamationCircle className="text-red-500"/>
        text = "There was no file metadata to reference"
    }
    return <Popover position="bottom" shadow="md" opened={opened}>
        <Popover.Target>
            <UnstyledButton onClick={toggle}>
                {icon}
            </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown className="border-0 bg-black p-4 rounded-2xl">
            <VStack>
                <TextSm inverse>{text}</TextSm>)
            </VStack>
        </Popover.Dropdown>
    </Popover>
}

function RenderableFileInfoWidget({home_id, selectedFileVersion}: {
    home_id: string,
    selectedFileVersion: AssessmentFile
}) {
    const [opened, {toggle}] = useDisclosure(false);
    const {data, error, isPending} = trpc.HOMEOWNER.getConversionInfoForAssessmentFile.useQuery({
        home_id: home_id,
        s3_url: selectedFileVersion.s3_url
    })
    if (isPending) {
        return <Loader/>
    }
    let icon
    let text
    if (error) {
        icon = <IconExclamationCircle className="text-red-500"/>
        text = "There was an error loading details about the APS file upload and conversion"
    } else if (data.conversionStatus == 'success') {
        return <IconCircleCheckFilled className="text-green-500"/>
    } else {
        if (data.conversionStatus == 'failed') {
            text = "The APS Conversion Failed"
            icon = <IconExclamationCircle className="text-red-500"/>
        } else {
            icon = <IconExclamationCircle className="text-yellow-500"/>
            if (data.conversionStatus == 'not_found') {
                if (data.objectExists) {
                    text = "The file was uploaded to APS, but the conversion is missing"
                } else {
                    text = "The file has not yet been uploaded to APS"
                }
            }
            text = `Status: ${data.conversionStatus}; Progress: ${data.conversionProgress}`
        }
    }
    return <Popover position="bottom" shadow="md" opened={opened}>
        <Popover.Target>
            <UnstyledButton onClick={toggle}>
                {icon}
            </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown className="border-0 bg-black p-4 rounded-2xl">
            <VStack>
                <TextSm inverse>{text}</TextSm>)
            </VStack>
        </Popover.Dropdown>
    </Popover>
}

function AssessmentFileVersionSelectWidget({sortedFileVersions, selectedFileVersion, setSelectedFileVersion}: {
    sortedFileVersions: Array<AssessmentFile>,
    selectedFileVersion?: AssessmentFile,
    setSelectedFileVersion: (f: AssessmentFile) => void
}) {

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: (eventSource) => {
            if (eventSource === 'keyboard') {
                combobox.selectActiveOption();
            } else {
                combobox.updateSelectedOptionIndex('active');
            }
        },
    });
    return <Combobox
        store={combobox}
        size="xl"
        disabled={!sortedFileVersions}
    >
        <Combobox.Target targetType="button">
            <InputBase
                component="button"
                type="button"
                pointer
                rightSection={<Combobox.Chevron/>}
                rightSectionPointerEvents="none"
                onClick={() => combobox.toggleDropdown()}
            >
                {selectedFileVersion
                    ? selectedFileVersion.name
                    : null
                }
            </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
            <Combobox.Options>
                {sortedFileVersions.map((f, i) => <UnstyledButton
                    className={classNames("first:border-0 first:mt-0 mt-2 border-t border-black border-solid w-full", {"bg-gray-200": f.s3_url == selectedFileVersion?.s3_url})}
                    key={i}
                    onClick={() => {
                        setSelectedFileVersion(f)
                        combobox.toggleDropdown()
                    }}><FileComboboxItem file={f}/>
                </UnstyledButton>)}
            </Combobox.Options>
        </Combobox.Dropdown>
    </Combobox>
}

function AssessmentFileUploadWidget({assessment_id, fileEnum}: {
    assessment_id: string,
    fileEnum: AssessmentFilesEnum
}) {
    const [fileUpload, setFileUpload] = useState<File | null>(null)
    const {
        data: saveUrlResponse,
        isError: isSaveUrlError,
        error: saveUrlError,
        isPending: isSaveUrlPending,
        mutate: saveUrl
    } =
        trpc.ADMIN.saveAssessmentFileUploadUrl.useMutation(getUseMutationOpt(trpc.useUtils(), () => setFileUpload(null)))
    const {
        data: fileUploadUrl
        , isError: isUploadFileError,
        error: fileUploadError,
        isPending: isUploadFilePending,
        mutate: uploadFile
    } = trpc.ADMIN.getAssessmentFileUploadUrl.useMutation({
        onSuccess: async ({url, nonce}: { url: string, nonce: FileUploadNonce }) => {
            try {
                await fetch(url, {
                    method: "PUT",
                    body: fileUpload!,
                    headers: {
                        "Content-Type": fileUpload!.type
                    }
                })
                saveUrl(nonce)
            } catch (e) {
                console.log("upload error", e)
            }
        }
    })
    return <HStack leftCenter>
        <FileInput
            value={fileUpload}
            onChange={setFileUpload}
            placeholder={`Select File ...`}
            classNames={{input: "border-black text-black"}}
            clearable
        />
        <Button disabled={!fileUpload || isUploadFilePending}
                onClick={() => uploadFile({
                    assessment_id: assessment_id,
                    contentType: fileUpload!.type,
                    assessmentFileEnum: fileEnum,
                    fileName: fileUpload!.name
                })}
        >{isUploadFilePending || isSaveUrlPending ? <Loader/> : "Upload"}</Button>
        {isUploadFileError && <span className="text-error">{fileUploadError.message}</span>}
        {isSaveUrlError && <span className="text-error">{saveUrlError.message}</span>}
    </HStack>
}

function AssessmentFileDownloadWidget({triggerDownload, selectedFileVersion}: {
    triggerDownload: boolean,
    selectedFileVersion?: AssessmentFile
}) {
    const {
        data: fileDownloadUrl,
        isPending: isFileDownloadUrlPending,
        mutate: getDownloadUrl,
        reset: resetDownloadUrl
    } = trpc.ADMIN.getAssessmentFileDownloadUrl.useMutation({
        onSuccess: async ({url}: { url: string }) => {
            if (triggerDownload) {
                window.open(url, "_blank")
            }
        }
    })
    useEffect(() => {
        if (triggerDownload && selectedFileVersion) {
            getDownloadUrl({s3Url: selectedFileVersion.s3_url})
        }
    }, [triggerDownload, getDownloadUrl, selectedFileVersion]);
    useEffect(() => {
        resetDownloadUrl()
    }, [selectedFileVersion,resetDownloadUrl]);
    return <Button className="h-full" disabled={!selectedFileVersion} onClick={() => {
        if (fileDownloadUrl) {
            window.open(fileDownloadUrl.url, "_blank")
        } else if (selectedFileVersion) {
            getDownloadUrl({s3Url: selectedFileVersion.s3_url})
        }
    }}>
        {fileDownloadUrl ? <IconDownload/> : isFileDownloadUrlPending ?
            <Loader/> :
            <span className="block whitespace-break-spaces max-w-20 break-words">Click To Get Download Link</span>}
    </Button>
}

function FileComboboxItem({file}: { file: AssessmentFile }) {
    return <VStack center>
        <TextSm>{lastNChars(file.name, 30)}</TextSm>
        <TextSm>{file.created_by}</TextSm>
        <TextSm>{file.created_date}</TextSm>
    </VStack>
}