import {createFileRoute} from '@tanstack/react-router'
import {
    trpc,
} from '~/lib-client'
import {AdminUserList} from '@seeair/admin-components';
import {Tabs} from "@mantine/core";
import {IconClock, IconSelectAll} from "@tabler/icons-react";
import {COLOR_WHITE, FullWithDesignedSection} from "@seeair/shared-components";
import React from 'react'
import {AdminUserTable} from "@seeair/admin-components";

export const Route = createFileRoute('/admin/_admin/')({
    component: function AdminComponent(){
        const [recentUsers] = trpc.ADMIN.listRecentUsersAggregate.useSuspenseQuery()
        return <FullWithDesignedSection background={COLOR_WHITE}>
            <Tabs defaultValue="recent">
                <Tabs.List>
                    <Tabs.Tab value="recent" leftSection={<IconClock/>}>Recent</Tabs.Tab>
                    <Tabs.Tab value="all" leftSection={<IconSelectAll/>}>All</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="recent">
                    <AdminUserTable users={recentUsers} />
                </Tabs.Panel>
                <Tabs.Panel value="all">
                    <AdminUserList />
                </Tabs.Panel>
            </Tabs>
        </FullWithDesignedSection>
    }
})
