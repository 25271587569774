import {createFileRoute, Outlet} from "@tanstack/react-router";
import {Layout} from "@seeair/shared-components";
import {Helmet} from "react-helmet-async";
import React from "react";

export const Route = createFileRoute('/auth/_auth')({
    component: () =>
        <Layout>
            <Helmet>
                <title>SeeAir Login</title>
                <meta name="description"
                      content="SeeAir Login"/>
                <link rel="canonical" href="https://seeair.com/auth"/>
            </Helmet>
            <Outlet/>
        </Layout>
})