import {DesignedButton, getUseMutationOpt, HStack} from "@seeair/shared-components";
import React, {useState} from "react";
import {Assessment} from "@seeair/schemas";
import { Loader, TextInput} from "@mantine/core";
import {IconCancel, IconPencil} from "@tabler/icons-react";
import {trpc} from "~/lib-client";

export function AssessmentHubspotLinkWidget({assessment}: { assessment: Assessment }) {
    const [editing,setEditing] = useState(false)
    const [hubspotDealId,setHubspotDealId] = useState(assessment.hubspot_deal_id)
    const {
        isPending: setHubspotDealIdIsPending,
        mutate: saveHubspotDealId
    } = trpc.ADMIN.setHubspotDealId.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <HStack leftCenter shrink classNames="border-primary-light-blue px-4">
        <TextInput
            label="hubspot_deal_id"
            disabled={!editing}
            value={hubspotDealId ?? ""}
            onChange={(event) => setHubspotDealId(event.target.value)}
        />
        <DesignedButton
            disabled={(editing && hubspotDealId == assessment.hubspot_deal_id) || setHubspotDealIdIsPending}
            onClick={() => {
                if (editing) {
                    saveHubspotDealId({
                        assessment_id: assessment.assessment_id,
                        hubspot_deal_id: hubspotDealId
                    })
                    setEditing(false)
                } else {
                    setEditing(true)
                }
            }}
        >
            {setHubspotDealIdIsPending ? <Loader/> : editing ? "Save" : <IconPencil/>}
        </DesignedButton>
        {editing && <React.Fragment>
            <DesignedButton m0 onClick={()=>{
                setEditing(false)
                setHubspotDealId(assessment.hubspot_deal_id)
            }}><IconCancel /></DesignedButton>
        </React.Fragment>
        }
    </HStack>
}