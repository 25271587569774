import {createFileRoute} from '@tanstack/react-router'
import { trpc} from '~/lib-client'
import {AssessmentFile, HomeAggregate} from "@seeair/schemas";
import {AbsoluteCenterStack, DigitalTwin, TextError} from "@seeair/shared-components";
import React from "react";
import {getLatestFile, getLatestFinishedAssessment} from "@seeair/shared";


export const Route = createFileRoute('/homes/_homes/$home_id/digital-twin')({
	component: function DigitalTwinRoute(){
		const {home_id} = Route.useParams()
		const [home] = trpc.HOMEOWNER.getHomeAggregate.useSuspenseQuery({home_id})
		const latestAssessment = getLatestFinishedAssessment(home)
		if(latestAssessment == 'not_found') {
			return <DigitalTwinNotReady />
		}
		const latestRenderingFile = getLatestFile(latestAssessment, 'capture_rendering_enhanced')
		if(latestRenderingFile == 'not_found') {
			return <DigitalTwinNotReady />
		}
		return <DigitalTwinWrapper home={home} file={latestRenderingFile} />
	}
})
function DigitalTwinNotReady() {
	return <AbsoluteCenterStack><TextError>Model Not Yet Ready</TextError></AbsoluteCenterStack>
}
function DigitalTwinWrapper({home,file}:{home:HomeAggregate,file:AssessmentFile}) {
	const [ urnResponse ] = trpc.HOMEOWNER.getConversionInfoForAssessmentFile.useSuspenseQuery({
		home_id:home.home_id,
		s3_url:file.s3_url
	})
	if (!urnResponse || !urnResponse.urn || !urnResponse.objectExists || urnResponse.conversionStatus != 'success') {
		return <DigitalTwinNotReady/>
	}
	return <DigitalTwin home={home} urn={urnResponse.urn}/>
}