import { Loader, Tooltip, UnstyledButton} from "@mantine/core";
import {IconChevronRight, IconInfoCircle, IconShare3, IconX} from "@tabler/icons-react";
import classNames from "classnames";
import React,{ReactNode} from "react";

export function DeleteButton({onClick,isPending}:{onClick:()=>void,isPending:boolean}) {
    return <UnstyledButton
        disabled={isPending}
        className="ml-3 p-2 transition-all rounded-l hover:bg-gray-200"
        onClick={onClick}
    >
        {isPending
            ? <Loader size="1rem" />
            : <IconX size="1rem" />
        }
    </UnstyledButton>
}
export function ShareButton({onClick}:{onClick:()=>void}) {
    return <UnstyledButton
        onClick={onClick}
        className="ml-3 p-2 transition-all rounded-l hover:bg-gray-200"
    >
        Share&nbsp;<IconShare3 size="1rem"/>
    </UnstyledButton>
}
export function CollapseToggle({onClick,isOpen,title}:{onClick:()=>void,title:string,isOpen:boolean}) {
    return <UnstyledButton
        onClick={onClick}
        >
        {title}&nbsp;<IconChevronRight className={classNames("text-gray-400 transition-all",{"rotate-90":isOpen})}/>
    </UnstyledButton>
}
export function InfoTooltip({label,center,mx4}:{center?:boolean,mx4?:boolean,label: string | number | true | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<ReactNode> | React.ReactPortal}){
    return <Tooltip
        className={classNames("cursor-pointer p-2 pl-0",{"mx-4":mx4})}
        classNames={{tooltip:classNames("p-4 break-words",{"text-center":center})}}
        multiline
        label={label}
        w={400}
    >
        <IconInfoCircle
            size="2rem"
        />
    </Tooltip>
}
