import {Home} from "@seeair/schemas";
import {HSpacer, PageSection, RStack, SearchResultRow, TextBase, TextXl, VStack} from "./DesignBase.js";
import {Link} from "@tanstack/react-router";
import {ChevronRight} from './Icons.js';
import React from "react";
import {buildShortAddress} from "@seeair/shared";

export function HomesList({homes}: { homes: Array<Home> }) {
    if(homes.length == 0) {
        return <NoHomes />
    }
    return <PageSection title="Homes">
        {homes.map((home) => (
            <SearchResultRow key={home.home_id}>
                <Link
                    preload={false}
                    key={home.home_id}
                    to="/homes/$home_id"
                    params={{home_id: home.home_id}}
                    className="w-full"
                >
                    <div className="flex flex-row items-center w-full">
                        <img src={home.thumbnail_url || undefined} className="aspect-square h-16 mr-8"/>
                        <TextXl wFull>{buildShortAddress(home.address)}</TextXl>
                        <HSpacer />
                        <ChevronRight/>
                    </div>
                </Link>
            </SearchResultRow>
        ))}
    </PageSection>
}
export function NoHomes() {
    return <RStack style={{background:"url(/design-assets/background1.png) lightgray 50% / cover no-repeat", minHeight: "60rem", width: "100%"}}>
        <div className="flex flex-col h-full w-full">
            <HSpacer/>
        </div>
        <div className="flex flex-col h-full w-full rounded-2xl p-10 mt-20 mx-10 md:mt-0 md:mr-20 md:ml-0" style={{
            background: "linear-gradient(111deg, rgba(202, 240, 248, 0.80) -5.88%, rgba(255, 255, 255, 0.80) 19.61%, rgba(255, 255, 255, 0.80) 49.34%, rgba(202, 240, 248, 0.80) 91.69%)",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.20)"
        }}>
            <HSpacer/>
            <VStack>
                <TextXl>Our Energy & Health Assessment is currently invite only</TextXl>
                <ul>
                    <li><TextBase>If you already signed up for our waitlist, we will reach out to you when we are ready to work with you on your assessment.</TextBase></li>
                    <li><TextBase>If you have not yet signed up for our waitlist, please do so from our </TextBase><Link to="/">homepage</Link></li>
                    <li><TextBase>If you believe this is an error please contact customer service at </TextBase><a href="mailto:support@seeair.com">support@seeair.com</a></li>
                </ul>
            </VStack>
        </div>
    </RStack>
}