import {Assessment, HomeAggregate} from "@seeair/schemas";
import {DesignedButton, getUseMutationOpt, HStack, VStack} from "@seeair/shared-components";
import ReactJson from "@microlink/react-json-view";
import {trpc} from "~/lib-client";
import React from 'react'

export function AssessmentSolarPanel({home, assessment}: { home: HomeAggregate, assessment: Assessment }) {
    const {mutate, isPending} = trpc.ADMIN.pullSolarData.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack center>
        <ReactJson src={assessment.solar_data ?? {}} collapsed={1}/>
        <HStack>
            <DesignedButton
                onClick={() => mutate({home_id: home.home_id, assessment_id: assessment.assessment_id})}
                disabled={isPending}>Pull
            </DesignedButton>
        </HStack>
    </VStack>
}