import {Project, ProjectAggregate, Recommendation} from "@seeair/schemas";
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {Accordion, SegmentedControl} from "@mantine/core";
import {trpc} from "~/lib-client";
import {notifications} from "@mantine/notifications";
import classNames from "classnames";
import {IconChevronDown} from "@tabler/icons-react";
import {Proforma} from "./Proforma.js";
import {HStack, ProjectIcon, TextBase, TextLg, TextLink, TextRLg, TextSmItalic, TextXl, VStack} from "./DesignBase.js";
import {SheetsDataContext} from './SheetsDataContext.js';
import {Badge} from './DesignComponents.js';
import {
    IconHeartChecked,
    IconHeartUnchecked,
    IconLeafChecked,
    IconLeafUnchecked,
    IconPriceTagChecked,
    IconPriceTagUnchecked
} from './Icons.js';
import {recDataToProformaData, recStatusToString, recToNum} from "@seeair/shared";

export function RecommendationsAccordion({recs, projects, filteredRecs, splitView}: {
    recs: Array<Recommendation>,
    projects: Array<ProjectAggregate>,
    filteredRecs: Array<number>,
    splitView: boolean
}) {
    const sortedRecs = [...recs]
        .filter(r => filteredRecs.length == 0 || filteredRecs.includes(parseInt(r.original_rec_id)))
        .sort((a, b) => recToNum(a, projects) - recToNum(b, projects))
    const [selected, setSelected] = useState(sortedRecs[0]?.original_rec_id ?? null)
    useEffect(() => {
        setSelected(sortedRecs[0]?.original_rec_id ?? null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredRecs]);
    return <VStack classNames="my-4">
        <Accordion
            transitionDuration={300}
            maw={"100%"}
            variant="separated"
            radius="xl"
            value={selected}
            onChange={setSelected}>
            {sortedRecs
                .map(r => {
                    let project: Project | undefined = undefined
                    if (r.project_id) {
                        project = projects.find(p => p.project_id == r.project_id)
                    }
                    return <Accordion.Item value={r.original_rec_id}
                                           key={r.recommendation_id}
                                           className="border-primary-light-blue mx-4">
                        <Accordion.Control icon={<VStack>
                            <ProjectIcon lg rec_number={r.original_rec_id}/>
                        </VStack>}>
                            <RecommendationPanelHeader rec={r} project={project} splitView={splitView}/>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <RecommendationPanel rec={r}
                                                 key={r.recommendation_id}
                                                 project={project}
                                                 initiallyExpanded={false /*sortedRecs.length == 1*/}/>
                        </Accordion.Panel>
                    </Accordion.Item>
                })}
        </Accordion>
        {sortedRecs.length != recs.length && <TextSmItalic center wFull
                                                           classNames="mt-8">{`${recs.length - sortedRecs.length} filtered results`}</TextSmItalic>}
    </VStack>
}

export function RecommendationPanelHeader({rec, project, splitView}: {
    rec: Recommendation,
    project: Project | undefined | null
    splitView: boolean,
}) {
    return <HStack leftCenter>
        <div className="flex-shrink flex-grow-0">
            {project
                ? <Badge blue><b>Project</b> {project.project_title}</Badge>
                : <Badge
                    green={rec.status == 'in_progress'}
                    gray={rec.status == 'not_started'}
                ><b>Status</b> {recStatusToString(rec)}
                </Badge>
            }
        </div>
        {
            splitView
                ? <TextXl classNames="ml-8">{rec.title}</TextXl>
                : <TextRLg center block wFull classNames="flex-grow flex-shrink capitalize">{rec.title}</TextRLg>
        }

    </HStack>
}

export function RecommendationPanel({rec, project, initiallyExpanded}: {
    rec: Recommendation,
    project: Project | undefined | null,
    initiallyExpanded: boolean
}) {
    const {recommendationsSheet} = useContext(SheetsDataContext)
    const matchingRec = recommendationsSheet.find((_rec) => _rec.id === rec.original_rec_id)
    const [expanded, setExpanded] = useState(initiallyExpanded)
    const utils = trpc.useUtils()
    const {
        isPending: isSetRecStatusPending,
        mutate: setRecStatus
    } = trpc.HOMEOWNER.changeRecommendationStatus.useMutation({
        onSuccess: async (_, variables) => {
            await utils.HOMEOWNER.invalidate()
            if (variables.status == "in_progress" && rec.type == "PRO") {
                notifications.show({message: "Your SeeAir Energy Advisor will be in touch shortly to start this project"})
            } else {
                notifications.show({message: "Update Success"})
            }
        },
        onError: (error) => {
            notifications.show({message: `Failure: ${error.message}`})
        }
    })
    return <div className={classNames("border-t-primary-light-blue",)}>
        {!project && <HStack center>
            <SegmentedControl
                className="my-4"
                disabled={isSetRecStatusPending}
                data={[
                    {label: "Not Started", value: "not_started"},
                    {label: "In Progress", value: "in_progress"},
                    {label: "Done", value: 'done'}]}
                value={rec.status}
                onChange={(v) => {
                    setRecStatus({
                        home_id: rec.home_id,
                        recommendation_id: rec.recommendation_id,
                        status: v as 'not_started' | 'done' | 'in_progress'
                    })
                }}
            />
        </HStack>}

        <ImpactSummary checkedIcon={<IconHeartChecked/>} uncheckedIcon={<IconHeartUnchecked/>}
                       score={matchingRec?.healthCategory || 0}
                       summary={matchingRec?.healthSummary || ""} title={"Health Impact"}
                       description={matchingRec?.healthDescription || ""} expanded={expanded} category="health"
                       rec={null}/>
        <ImpactSummary checkedIcon={<IconLeafChecked/>} uncheckedIcon={<IconLeafUnchecked/>}
                       score={matchingRec?.climateCategory || 0}
                       summary={matchingRec?.climateSummary || ""} title={"Climate Impact"}
                       description={matchingRec?.climateDescription || ""} expanded={expanded}
                       category="climate" rec={null}/>
        <ImpactSummary checkedIcon={<IconPriceTagChecked/>} uncheckedIcon={<IconPriceTagUnchecked/>}
                       score={matchingRec?.priceCategory || 0}
                       summary={matchingRec?.priceSummary || ""} title={"Price Impact"}
                       description={matchingRec?.priceDescription || ""} expanded={expanded}
                       rec={rec} category="price"/>
        {project &&
            <VStack center>
                {
                    project.hubspot_quote_url
                        ? <a className="mb-8" href={project.hubspot_quote_url} target="_blank" rel="noreferrer">
                            <TextLink>View Quote, Sign & Pay</TextLink>
                        </a>
                        : <TextBase light>Quote Not Ready</TextBase>
                }
            </VStack>
        }
        <a onClick={() => setExpanded(!expanded)} className="flex flex-row justify-center items-center">
            <TextLink classNames="transition">{
                expanded
                    ? "Hide"
                    : "More Info"
            }
            </TextLink><IconChevronDown
            className={classNames("text-blue-400 transition transform", {"actually-rotate-180": expanded})}/></a>
    </div>
}

function RecDataProforma({rec}: { rec: Recommendation }) {
    return <Proforma title="Estimated Cost"
                     finePrint="* All costs are estimates and subject to change prior to contract sign off"
                     data={recDataToProformaData(rec)}
    />
}

function
ImpactSummary({checkedIcon, uncheckedIcon, score, summary, title, description, expanded, category, rec}: {
    checkedIcon: ReactElement,
    uncheckedIcon: ReactElement,
    score: number,
    summary: string,
    title: string,
    description: string,
    expanded: boolean,
    category: 'health' | 'price' | 'climate',
    rec: Recommendation | null
}) {
    const n = Math.floor(score)
    return <div className={classNames("flex items-center", {
        "flex-row": !expanded,
        "flex-col": expanded
    })}>
        {
            expanded
                ? <React.Fragment>
                    {
                        // we will show the score and description if it is there, but if we have a proforma and no description, we'll skip score and description
                        (category != 'price' || (!rec || description.length > 0)) && <React.Fragment>
                            <div className={classNames("px-8 flex flex-row items-center justify-start w-full")}>
                                {[
                                    ...list(n).map(i => <React.Fragment key={`check-${i}`}>{checkedIcon}</React.Fragment>),
                                    ...(n < 3 ? list(3 - n).map(i => <React.Fragment
                                        key={`uncheck-${i}`}>{uncheckedIcon}</React.Fragment>) : []),
                                    <TextLg key={'title'} classNames="ml-8">{title}</TextLg>
                                ]}
                            </div>
                            <TextBase classNames={classNames("mx-8")}>
                                {description}
                            </TextBase>
                        </React.Fragment>
                    }
                    {rec && expanded && <RecDataProforma rec={rec}/>}
                </React.Fragment>
                : <React.Fragment>
                    <div className={classNames("px-8 flex flex-row items-center justify-start")}>
                        {checkedIcon}
                    </div>
                    <TextLg classNames={classNames("mx-8")}>
                        {summary}
                    </TextLg>
                </React.Fragment>
        }
    </div>
}

function list(n: number): Array<number> {
    return Array.from(new Array(n).keys())
}