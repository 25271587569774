import {
    HDivider,
    HStack,
    ProjectIcons, TextLinkSm,
    TextSm, TextXl, TextXs, TextXsItalic,
    VStack
} from "./DesignBase.js";
import React, {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames";
import { ProformaData, ProformaIncentive, ProformaItem} from "@seeair/schemas";
import {
    calculateNetCost,
    calculatePaybackPeriod,
    formatMoney,
    formatMoneyRange,
    formatQuantity,
    formatYearsRange, isProformaCostFiniteAndNotNegative
} from "@seeair/shared";
import {InfoTooltip} from './MantineExtensions.js';

function ProformaText({children, indent, bold, url, icon}: PropsWithChildren<{
    indent?: boolean,
    bold?: boolean,
    url?: string,
    icon?: ReactElement
}>) {
    let component: ReactElement
    if (url) {
        component = <a href={url} target="_blank" rel="noreferrer">
            <TextLinkSm light m0 classNames={classNames("leading-8", {

                "font-semibold": bold
            })}>{children}</TextLinkSm>
        </a>
    } else {
        component = <TextSm light m0 classNames={classNames("leading-8", {})}>{children}</TextSm>
    }
    return <HStack leftCenter shrink classNames={classNames({"ml-8": indent})}>
        {icon}{component}
    </HStack>
}
function ProformaItemLine({item}:{item:ProformaItem}) {
    return <HStack between>
        <ProformaText indent icon={<ProjectIcons sm rec_numbers={item.recNumbers}/>}>{item.title}</ProformaText>
        <ProformaText>
            <span className="w-12 text-left inline-block">{formatQuantity(item.quantity)}</span>
            <span className="w-8 text-center inline-block">x</span>
            <span className="w-12 text-left inline-block">{formatMoney(item.price_per_unit)}</span>
            <span className="w-8 text-center inline-block">=</span>
            <span
                className="w-16 text-right inline-block">{formatMoney(item.quantity * item.price_per_unit)}</span>
        </ProformaText>
    </HStack>
}
export function Proforma({title, data, finePrint}: {
    title: string,
    finePrint?: string,
    data: ProformaData
}) {
    const hasIncentives = data.incentives.length > 0
    const hasLineItems = data.lineItems.length > 0
    return <VStack classNames="px-8 sm:px-16 pb-8 sm:pb-16 border-t-primary-light-blue bg-white">
        <TextXl medium center>{title}</TextXl>
        {hasLineItems && <VStack>
            <ProformaText>Line Items</ProformaText>
            {data.lineItems.map((item, i) => <ProformaItemLine item={item} key={i} />)}
        </VStack>}
        <HDivider/>
        <HStack between>
            <ProformaText>{hasLineItems && "Total "}Cost{hasIncentives && " Before Incentives or Rebates"}</ProformaText>
            <ProformaText>{formatMoneyRange(data.gross)}</ProformaText>
        </HStack>
        {hasIncentives && <ProformaIncentiveSection data={data}/>
        }
        <ProformaPaybackSection data={data}/>
        {finePrint && <TextXsItalic classNames="mt-8">{finePrint}</TextXsItalic>}
    </VStack>
}


function ProformaIncentiveLine({inc}: { inc: ProformaIncentive }) {
    return <HStack between>
        <HStack leftCenter>
            <ProformaText indent url={inc.url} icon={<ProjectIcons sm rec_numbers={inc.recNumbers}/>}>
                {inc.title}
                {inc.description && <TextXs>&nbsp;({inc.description})</TextXs>}</ProformaText>
        </HStack>
        <HStack rightCenter grow>
            <ProformaText>
                {
                    formatMoneyRange(inc.cost)
                }
            </ProformaText>
        </HStack>
    </HStack>
}

function ProformaIncentiveSection({data}: { data: ProformaData }) {
    const immediateIncentives = data.incentives.filter(inc => inc.timeOfPurchase)
    const rebateIncentives = data.incentives.filter(inc => !inc.timeOfPurchase)
    return <VStack>
        <HDivider/>
        {
            immediateIncentives.length > 0 && <React.Fragment>
                <HStack>
                    <ProformaText>Incentives</ProformaText>
                    <InfoTooltip mx4 center label="These are deducted from your purchase price today."/>
                </HStack>
                {
                    immediateIncentives.map((inc, i) => <ProformaIncentiveLine inc={inc} key={i}/>)
                }
            </React.Fragment>
        }
        {
            rebateIncentives.length > 0 && <React.Fragment>
                <HStack>
                    <ProformaText>Rebates & Tax Credits</ProformaText>
                    <InfoTooltip mx4 center
                                 label="We'll help you apply for these rebates and fill out the appropriate tax forms so you can get paid back later."/>
                </HStack>
                {
                    rebateIncentives.map((inc, i) => <ProformaIncentiveLine inc={inc} key={i}/>)
                }
            </React.Fragment>
        }
        <HDivider/>
        <HStack between>
            <ProformaText>Net Cost after Incentive</ProformaText>
            <ProformaText>{formatMoneyRange(calculateNetCost(data,false))}</ProformaText>
        </HStack>
        <HStack between>
            <ProformaText>Net Cost after Rebates</ProformaText>
            <ProformaText>{formatMoneyRange(calculateNetCost(data,true))}</ProformaText>
        </HStack>
    </VStack>
}

function ProformaPaybackSection({data}: { data: ProformaData }) {
    const payback = calculatePaybackPeriod(data)
    if (isProformaCostFiniteAndNotNegative(data.annual_savings)) {
        return <React.Fragment>
            <HDivider/>
            <HStack between>
                <ProformaText>Annual Energy Savings</ProformaText>
                <ProformaText>{formatMoneyRange(data.annual_savings)}</ProformaText>
            </HStack>
            <HStack between>
                <ProformaText>Payback Period</ProformaText>
                <ProformaText>{formatYearsRange(payback)}</ProformaText>
            </HStack>
        </React.Fragment>
    }
    return null
}
