import {z} from "zod";

export const ConversionInfoSchema = z.object({
    urn: z.string(),
    objectExists: z.boolean(),
    conversionStatus: z.enum(['success', 'pending', 'inprogress', 'failed', 'timeout', 'not_found']),
    conversionProgress: z.string(),
})
export type ConversionInfo = z.infer<typeof ConversionInfoSchema>

export interface IAutodeskClient {
    getConversionInfo(file: { s3_url: string }): Promise<ConversionInfo>;

    idempotentCreateDerivative(file: { s3_url: string }): Promise<void>;

    getReadOnlyToken(): Promise<string>;

    getDerivativeDownloadBuffer(file: { s3_url: string }): Promise<Buffer>;

    getDerivativeThumbnailBuffer(file: { s3_url: string }): Promise<Buffer>;

    getAssessmentFileForEncodedUrn(urn: string): { s3_url: string };

    getUrnForAssessmentFile(file: { s3_url: string }): string;

}