import React, {PropsWithChildren, useEffect} from "react";
import { User} from "@seeair/schemas";
import {trpc} from "~/lib-client";
import * as Sentry from "@sentry/react";

type UserData = {
    user:User | null
    isLoading:boolean
}
const DefaultUserData:UserData = {user:null,isLoading:true}
export const UserDataContext = React.createContext<UserData>(DefaultUserData)
export function QueryUserDataProvider({children}:PropsWithChildren){
    const {data:user,isLoading} = trpc.PUBLIC.getCurrentUser.useQuery()
    useEffect(()=>{
        Sentry.setUser(user ?? null)
    },[user])
    return (
        <UserDataContext.Provider value={{user:user||null,isLoading}}>
            {children}
        </UserDataContext.Provider>
    )
}