import {
    Assessment,
    BaseHomeDetailsSchema,
    DerivedHomeDetailsSchema,
    HeatingAndCoolingWalkthroughDetailsSchema,
    HomeDetailsSchema,
    HomeWalkthroughDetailsSchema
} from "@seeair/schemas";
import Form from "@rjsf/core";
import {ZodObject} from "zod";
import validator from '@rjsf/validator-ajv8';
import {zodToJsonSchema} from "zod-to-json-schema";
import {RegistryWidgetsType, RJSFSchema, RJSFValidationError, UiSchema, WidgetProps} from "@rjsf/utils";
import {JSONSchema7, JSONSchema7TypeName} from "json-schema";
import { HStack, TextLg, TextSm, VStack} from "./DesignBase.js";
import {Accordion, Loader, NativeSelect} from "@mantine/core";
import {trpc} from "~/lib-client";
import {notifications} from "@mantine/notifications";
import {ReactElement, useEffect, useState} from "react";
import {getUseMutationOpt} from './mutationHelper.js';
import React from 'react'

export function EditMissingHomeDetailsPanel({assessment}: { assessment: Assessment }) {
    const homeDetails = assessment.home_details || {}
    const schemas: Array<[string, ZodObject<any>]> = [
        ["Base Data", BaseHomeDetailsSchema],
        ["Home Walkthrough Survey", HomeWalkthroughDetailsSchema],
        ["HVAC Walkthrough Survey", HeatingAndCoolingWalkthroughDetailsSchema],
        ["Derived Data", DerivedHomeDetailsSchema]]
    return <VStack>
        <Accordion>
            {
                schemas.map(([title, schema], i) => <Accordion.Item key={title} value={title}>
                    <Accordion.Control>
                        <TextLg>{title}</TextLg><TextSm
                        classNames="ml-4">{getMissingFieldCount(homeDetails, schema)} Missing</TextSm>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <PropertiesSchemaForm missing={true} data={homeDetails} schema={schema}
                                              idPrefix={`missing-props-${i}`} home_id={assessment.home_id}
                                              assessment_id={assessment.assessment_id}/>
                    </Accordion.Panel>
                </Accordion.Item>)
            }
            <Accordion.Item key="single" value="single">
                <Accordion.Control>
                    <TextLg>Choose A Single Field</TextLg>
                </Accordion.Control>
                <Accordion.Panel>
                    <SinglePropertySchemaForm readOnly={false} data={homeDetails} schema={HomeDetailsSchema}
                                              idPrefix="single-field"
                                              home_id={assessment.home_id} assessment_id={assessment.assessment_id}/>
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item key="all" value="all">
                <Accordion.Control>
                    <TextLg>All Fields</TextLg>
                </Accordion.Control>
                <Accordion.Panel>
                    <PropertiesSchemaForm missing={false} data={homeDetails} schema={HomeDetailsSchema}
                                          idPrefix="all-props" home_id={assessment.home_id}
                                          assessment_id={assessment.assessment_id}/>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    </VStack>

}

function getFilledFields(data: any, schema: ZodObject<any>) {
    return Object.keys(schema.shape).filter(k => {
        return (data[k] != null && data[k] != undefined)
    })
}

function getMissingFieldCount(data: any, schema: ZodObject<any>): number {
    return Object.keys(schema.shape).length - getFilledFields(data, schema).length
}

function validationErrorsToString(errors: Array<RJSFValidationError>) {
    return "Form Validation Error(s):\n" + errors.map(o => `${o.schemaPath} - ${o.message}`).join('\n\t')
}

const CustomCheckbox = function (props: WidgetProps) {
    const [v, setV] = useState(props.value == null ? 'Undefined' : props.value ? 'Yes' : 'No')
    useEffect(() => {
        props.onChange(v == 'Undefined' ? undefined : v == 'Yes')
    }, [v, props])
    return <NativeSelect
        value={v}
        label={props.label}
        onChange={(event) => setV(event.currentTarget.value)}
        data={['Yes', 'No', 'Undefined']}
    />
}

function PropertiesSchemaForm({schema, data, idPrefix, home_id, assessment_id, missing}: {
    data: { [_: string]: any },
    schema: ZodObject<any>,
    idPrefix: string,
    home_id: string,
    assessment_id: string,
    missing: boolean
}) {
    const [newlyEnteredHomeDetails, setNewlyEnteredHomeDetails] = useState({})
    const {
        mutate: updateHomeDetails,
        isPending
    } = trpc.HOMEOWNER.updateHomeDetails.useMutation(getUseMutationOpt(trpc.useUtils()))
    useEffect(() => {
        setNewlyEnteredHomeDetails({})
    }, [data, schema, setNewlyEnteredHomeDetails]);
    let formSchema: RJSFSchema
    if (missing) {
        const filledFields = getFilledFields(data, schema)
        const missingOnlySchema = schema.omit(filledFields.reduce((acc, v) => ({...acc, [v]: true}), {}))
        formSchema = zodToJsonSchema(missingOnlySchema) as RJSFSchema
    } else {
        formSchema = zodToJsonSchema(schema) as RJSFSchema
    }
    zodToJSONSchemaCleanup(formSchema)
    const uiSchema: UiSchema = generateUiSchema(formSchema, true)
    const widgets: RegistryWidgetsType = {
        CheckboxWidget: CustomCheckbox,
    }
    return isPending
        ? <HStack center><Loader /></HStack>
        : <Form
            onError={(e) => {
                notifications.show({message: validationErrorsToString(e)})
            }}
            widgets={widgets}
            schema={formSchema}
            uiSchema={uiSchema}
            validator={validator}
            formData={missing ? newlyEnteredHomeDetails : data}
            idPrefix={idPrefix}
            onChange={(event, id) => {
                setNewlyEnteredHomeDetails(event.formData)
            }}
            showErrorList={"bottom"}
            liveValidate={true}
            onSubmit={() => {
                updateHomeDetails({details: newlyEnteredHomeDetails, home_id, assessment_id})
            }}
        />
}

export function SinglePropertySchemaForm({schema, data, idPrefix, home_id, assessment_id, readOnly}: {
    data: { [_: string]: any },
    schema: ZodObject<any>,
    idPrefix: string,
    home_id: string,
    assessment_id: string,
    readOnly: boolean
}) {
    const [newlyEnteredHomeDetails, setNewlyEnteredHomeDetails] = useState({})
    const [editingProp, setEditingProp] = useState("")
    const {mutate: updateHomeDetails,isPending} = trpc.HOMEOWNER.updateHomeDetails.useMutation(getUseMutationOpt(trpc.useUtils()))
    useEffect(() => {
        setNewlyEnteredHomeDetails({})
    }, [data, schema, setNewlyEnteredHomeDetails]);
    let formComponent: ReactElement | null = null
    const widgets: RegistryWidgetsType = {
        CheckboxWidget: CustomCheckbox,
    }
    if(isPending) {
        formComponent = <HStack center><Loader /></HStack>
    } else if (editingProp) {
        const formSchema: RJSFSchema = zodToJsonSchema(schema.pick({[editingProp]: true})) as RJSFSchema
        zodToJSONSchemaCleanup(formSchema)
        const uiSchema: UiSchema = generateUiSchema(formSchema, false)
        formComponent = <Form
            onError={(e) => {
                notifications.show({message: validationErrorsToString(e)})
            }}
            widgets={widgets}
            readonly={readOnly}
            disabled={readOnly}
            schema={formSchema}
            uiSchema={uiSchema}
            showErrorList={"bottom"}
            validator={validator}
            formData={{[editingProp]: data[editingProp]}}
            idPrefix={idPrefix}
            onChange={(event) => {
                setNewlyEnteredHomeDetails({[editingProp]: event.formData[editingProp]})
            }}
            liveValidate={true}
            onSubmit={() => {
                updateHomeDetails({details: newlyEnteredHomeDetails, home_id, assessment_id})
            }}
        />
    }

    return <VStack>
        <NativeSelect
            value={editingProp}
            onChange={(event) => {
                setEditingProp(event.currentTarget.value)
            }}
            data={[
                {label: "Choose A Field", value: ""},
                ...Object.keys(schema.shape).map(k => ({value: k, label: schema.shape[k].description || k}))]}
        />
        {formComponent}
    </VStack>
}

// Adjusting the JSON schema for UI changes
function zodToJSONSchemaCleanup(schema7: JSONSchema7) {
    for (const key in schema7.properties || {}) {
        const prop = schema7.properties![key]!
        if (typeof prop != 'boolean') {
            prop.title = prop.description
            delete prop.description
            if (prop.type == 'array') {
                prop.uniqueItems = true
            }
        }
    }
}

function getTypeName(key: string, props: JSONSchema7["properties"]): JSONSchema7TypeName | JSONSchema7TypeName[] {
    if (!props || !props[key] || typeof props[key] == "boolean") {
        return "null"
    }
    return props[key].type || "null"
}

function getUiHintsForTypeName(typeName: JSONSchema7TypeName | JSONSchema7TypeName[], key: string, showLabels: boolean): {
    [s: string]: any
} {
    switch (typeName) {
        case 'array':
            return {
                "ui:widget": "checkboxes",
                "ui:options": {inline: true}
                // "classNames": "hidden-label"
            }
        case 'number':
            return {
                "ui:options": {label: showLabels},
                "ui:emptyValue": 0
            }
        case 'string':
            if (key.endsWith("image")) {
                return {
                    "ui:options": {label: showLabels},
                    "ui:emptyValue": "",
                    "classNames": "hidden-input"
                }
            } else {
                return {
                    "ui:options": {label: showLabels},
                    "ui:emptyValue": ""
                }
            }
        default:
            return {
                "ui:options": {label: showLabels},
            }
    }
}

// Generates UiSchema based on schema
function generateUiSchema(schema7: JSONSchema7, showLabels: boolean) {
    const props = schema7.properties || {}
    return Object.keys(props).reduce((acc, v) => ({
        ...acc,
        [v]: getUiHintsForTypeName(getTypeName(v, props), v, showLabels)
    }), {'ui:submitButtonOptions': {submitText: "Save"}})
}