import {createFileRoute} from "@tanstack/react-router";
import {AdminAssessmentPage} from "@seeair/admin-components";
import {TextError} from "@seeair/shared-components";
import {AdminAssessmentPageSearch, AdminAssessmentPageSearchSchema} from "@seeair/schemas"
import React from 'react'
import {trpc} from "~/lib-client";

export const Route = createFileRoute('/admin/_admin/assessment/$assessment_id')({
    component: function AdminAssessmentPageComponent() {
        const {assessment_id} = Route.useParams()
        const {tab} = Route.useSearch()
        const [{home}] = trpc.ADMIN.loadHomeAggregateByAssessmentId.useSuspenseQuery({assessment_id})
        const assessment = home?.assessments?.find(a => a.assessment_id == assessment_id)
        if (!home || !assessment) {
            return <TextError>Assessment Not Found</TextError>
        }
        return <AdminAssessmentPage
            home={home}
            assessment={assessment}
            tab={tab}
        />
    },
    validateSearch: (data)=>{
        // console.log(`validate search ${JSON.stringify(data)}`)
        if(data == null || Object.keys(data).length == 0){
            return {tab: 'home_details'} as AdminAssessmentPageSearch
        }
        return AdminAssessmentPageSearchSchema.parse(data)
    }
})


