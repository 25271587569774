import {createFileRoute, Link} from '@tanstack/react-router'
import {AuthenticationCheck, GradientBox, TextBase, TextLg, TextLink, TextSm, VStack} from "@seeair/shared-components";
import React from 'react'

export const Route = createFileRoute('/auth/_auth/verify-request')({
    component: () => <AuthenticationCheck shouldRedirect={(user) => user != null} redirectTo="/homes">
        <VStack center>
            <GradientBox>
                <VStack center>
                    <TextLg>
                        Check your email
                    </TextLg>
                    <TextBase>A sign in link has been sent to your email address.</TextBase>
                    <Link
                        preload={false}
                        to="/auth/signin"
                    ><TextLink>Go back</TextLink></Link>
                    <TextSm>
                        Can&apos;t find your link? Check your spam folder!
                    </TextSm>
                </VStack>
            </GradientBox>
        </VStack>
    </AuthenticationCheck>
})
