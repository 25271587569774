import {COLOR_WHITE, PRIMARY_BLUE, PRIMARY_LIGHT_BLUE} from './Theme.js';
import React, {PropsWithChildren, ReactElement} from "react";
import {AbsoluteCenterStack, HStack, Text3Xl, Text5Xl, TextXl} from './DesignBase.js';
import classNames from "classnames";
import {Link} from "@tanstack/react-router";
import {InfoTooltip} from './MantineExtensions.js';
import {IconPlayerPlay} from "@tabler/icons-react";

export function DesignedTwoColumnCard({imgSrc, title, text, linkTo, linkText, wide}: {
    imgSrc: string,
    title: string,
    text: string,
    linkTo?: string,
    linkText?: string
    wide?: boolean
}) {
    return <div
        className={classNames("my-8 rounded-2xl mx-2 sm:mx-12 flex flex-col md:flex-row justify-between items-center", {
            "w-max-200": !wide,
            "md:w-200": !wide,
        })}
        style={{
            background: "linear-gradient(119deg, rgba(0, 121, 233, 0.00) 46.36%, rgba(0, 121, 233, 0.24) 100%)",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.25)"
        }}>
        <div className="rounded-2xl" style={{
            width: "19rem",
            minWidth: "19rem",
            minHeight: "19rem",
            margin: "2.5rem",
            background: `url(${imgSrc}) lightgray 50% / cover no-repeat`
        }}/>
        <div className="flex flex-col mx-10 h-full" style={{
            minHeight: "19rem",
            margin: "2.5rem"
        }}>
            <span className="text-xl font-normal pt-0 mt-0 mb-4 pb-4">{title}</span>
            <span className="text-lg font-normal pb-4">{text}</span>
            {
                linkTo &&
                <div className="pb-4">
                    <DesignedLink to={linkTo}>
                        <span className="underline">{linkText}</span>
                    </DesignedLink>
                </div>
            }
        </div>
    </div>
}


export function DesignedButton({
                                   children,
                                   m0,
                                   opacity,
                                   boxShadow,
                                   disabled,
                                   onClick,
                                   inverse,
                                   submit,
                                   outline,
                                   wFull,
                                   className: cn
                               }: PropsWithChildren<{
    onClick?: () => void,
    m0?: boolean,
    opacity?: boolean,
    boxShadow?: boolean,
    disabled?: boolean,
    submit?: boolean,
    inverse?: boolean,
    outline?: boolean,
    wFull?: boolean,
    className?: string
}>) {
    return <button
        style={{
            background: inverse ? COLOR_WHITE : `linear-gradient(96deg, ${PRIMARY_BLUE} 64.98%, ${PRIMARY_LIGHT_BLUE} 147.53%)`,
            color: inverse ? PRIMARY_BLUE : COLOR_WHITE,
            border: outline ? `1px solid ${PRIMARY_LIGHT_BLUE}` : "none",
            boxShadow: boxShadow ? "0px 0px 50px 0px black" : "none"
        }}
        className={classNames("flex flex-row items-center min-h-12 rounded-2xl py-2 px-8", {
            "w-full": wFull,
            "max-w-fit": !wFull,
            "disabled": disabled,
            "cursor-pointer": !disabled,
            "cursor-not-allowed": disabled,
            "opacity-75": opacity || disabled,
            "m-4": !m0
        }, cn)}
        onClick={disabled ? () => {
        } : onClick}
        disabled={disabled}
        type={submit ? "submit" : "button"}
    >
        {children}
    </button>
}


export function DesignedPlayButton({text, onClick}: { text: string, onClick: () => void }) {
    return <AbsoluteCenterStack>
        <DesignedButton onClick={onClick} opacity boxShadow>
            <span className="text-4xl text-white font-light p-4">{text}</span><IconPlayerPlay strokeWidth={1.5}
                                                                                              size={40}
                                                                                              className="ml-2"/>
        </DesignedButton>
    </AbsoluteCenterStack>

}

export function DesignedLink({children, style, to, inactiveProps, activeProps}: PropsWithChildren<{
    to: string,
    style?: React.CSSProperties,
    inactiveProps?: { style: React.CSSProperties },
    activeProps?: { style: React.CSSProperties }
}>) {
    return <Link to={to}
                 className="cursor-pointer"
                 activeProps={activeProps}
                 inactiveProps={inactiveProps}
                 style={style}>
        {children}
    </Link>
}


export function DesignedPageSection({title, imgSrc, children}: PropsWithChildren<{ title: string, imgSrc?: string }>) {
    return (
        <div className="flex flex-col mb-10 last:mb-0">
            <div className="flex flex-row">
                {imgSrc && <img className="w-14 h-14 mr-10" src={imgSrc}/>}
                <TextXl>{title}</TextXl>
            </div>
            <div className="p-5 bg-neutral-200 even:bg-white even:py-10">{children}</div>
        </div>
    )
}

export function Badge({green, gray, blue, wFull, children, classNames: cns}: PropsWithChildren<{
    green?: boolean,
    blue?: boolean,
    gray?: boolean,
    wFull?: boolean,
    classNames?: string
}>) {
    return <div className={classNames(
        "text-center text-white rounded-2xl text-sm mt-2 py-1 px-3",
        {
            "bg-primary-light-blue": blue,
            "bg-black": !green && !gray && !blue,
            "bg-success": green,
            "bg-gray-400": gray,
            "w-full": wFull
        }
        , cns)}>
        {children}
    </div>
}

export const SuspenseLoader = (
    <AbsoluteCenterStack>
        <TextXl>Loading Your Healthy, Eco-Friendly Home</TextXl>
    </AbsoluteCenterStack>
)

export function BorderlessIconCard({icon, gradientColor, emphasis, text, info}: {
    icon: ReactElement,
    gradientColor: string,
    emphasis: string,
    text: string,
    info?: string
}) {
    return <HStack leftCenter style={{width: "auto"}}>
        <div className="h-20 w-20 p-4 m-12" style={{
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.20)",
            borderRadius: "1.25rem",
            background: `linear-gradient(218deg, ${gradientColor} -20.21%, rgba(255, 255, 255, 0.60) 31.21%, rgba(255, 255, 255, 0.60) 56.92%, ${gradientColor} 108.33%)`
        }}>
            <div className="h-12 w-12">{icon}</div>
        </div>
        <div>

            <Text3Xl normal>{emphasis} </Text3Xl>
            {info && <InfoTooltip label={info}/>}
            <br/>
            <TextXl>{text}</TextXl>
        </div>
    </HStack>
}

export function PageTitle({text}: { text: string }) {
    return <div style={{borderBottom: `2px solid ${PRIMARY_LIGHT_BLUE}`, width: "100%", marginBottom: "3rem"}}>
        <Text5Xl>{text}</Text5Xl>
    </div>
}