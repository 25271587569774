import React, {PropsWithChildren, useContext, useEffect} from "react";
import {UserDataContext} from './UserDataProvider.js';
import {useNavigate} from "@tanstack/react-router";
import {User} from "@seeair/schemas";
import {isSiteAdmin} from "@seeair/shared";

export function AuthenticationCheck({children, shouldRedirect, redirectTo,search}: PropsWithChildren<{
    shouldRedirect: (user: User | null) => boolean,
    redirectTo: string,
    search?:{[k:string]:string}
}>) {
    const {user, isLoading} = useContext(UserDataContext)
    const navigate = useNavigate()

    useEffect(() => {
        void (async () => {
            if (!isLoading) {
                if (shouldRedirect(user)) {
                    await navigate({to: redirectTo,search:search})
                }
            }
        })()
    }, [user, isLoading,navigate,shouldRedirect,redirectTo,search])
    return <React.Fragment>{children}</React.Fragment>
}

export function UserLoggedInRedirectToSignInCheck({children}: PropsWithChildren) {
    return <AuthenticationCheck
        shouldRedirect={(user) => user == null}
        redirectTo={"/auth/signin"}
    >
        {children}
    </AuthenticationCheck>
}
export function AdminUserRedirectToSignInCheck({children}:PropsWithChildren) {
    return <AuthenticationCheck shouldRedirect={(user)=>!isSiteAdmin(user)} redirectTo="/">
        {children}
    </AuthenticationCheck>
}