import React, {PropsWithChildren, ReactElement} from "react";
import cns from "classnames";
import classNames from "classnames";
import {COLOR_WHITE, PRIMARY_LIGHT_BLUE} from "./Theme.js";

type StackProps = {
    ymargin?: boolean
    relative?: boolean
    around?: boolean,
    between?: boolean,
    center?: boolean
    leftCenter?: boolean
    rightCenter?: boolean
    baseline?: boolean,
    wFull?: boolean,
    hFull?: boolean,
    grow?: boolean,
    shrink?: boolean,
    wrap?: boolean,
    style?: React.CSSProperties,
    classNames?: string,
}

function stackClassName(cn: string, {
    center,
    ymargin,
    relative,
    around,
    between,
    baseline,
    wFull,
    hFull,
    classNames,
    leftCenter,
    rightCenter,
    grow,
    shrink,
    wrap
}: StackProps) {
    return cns(classNames, cn, {
        "justify-center": center,
        "justify-start": leftCenter,
        "justify-end": rightCenter,
        "items-center": center || leftCenter || rightCenter,
        "items-baseline": baseline,
        "my-12": ymargin,
        "relative": relative,
        "justify-around": around,
        "justify-between": between,
        "w-full": wFull && !shrink,
        "h-full": hFull && !shrink,
        "flex-grow": grow,
        "flex-shrink": shrink,
        "flex-wrap": wrap,
    })
}

export function HStack({children, style, ...stackProps}: PropsWithChildren<StackProps>) {
    return <div className={stackClassName("flex flex-row", {...stackProps, wFull: true, hFull: true})}
                style={style}>
        {children}
    </div>
}

export function RHideStack({children, style, sm, md, xl, lg, reverse, ...stackProps}: PropsWithChildren<StackProps & {
    sm?: boolean,
    md?: boolean,
    lg?: boolean,
    xl?: boolean,
    reverse?: boolean
}>) {
    if (!sm && !lg && !xl) {
        md = true
    }
    const childrenArray = React.Children.toArray(children)
    return <div
        className={stackClassName(cns("flex items-center w-full h-full", reverse ? "flex-row-reverse" : "flex-row"), stackProps)}
        style={style}>
        {childrenArray[0]}
        <div className={cns("flex-initial hidden", {
            "xl:flex": xl,
            "lg:flex": lg,
            "md:flex": md,
            "sm:flex": sm
        })}>
            {childrenArray.slice(1)}
        </div>
    </div>
}

export function RStack({children, style, sm, md, xl, lg, reverse, ...stackProps}: PropsWithChildren<StackProps & {
    sm?: boolean,
    md?: boolean,
    lg?: boolean,
    xl?: boolean,
    reverse?: boolean,
}>) {
    if (!sm && !lg && !xl) {
        md = true
    }
    return <div
        className={stackClassName(cns("flex items-center w-full h-full", reverse ? "flex-col-reverse" : "flex-col", {
            //`${xl ? "xl" : lg ? "lg" : md ? "md" : sm ? "sm" : "md"}:flex-row`
            "xl:flex-row": xl,
            "lg:flex-row": lg,
            "md:flex-row": md,
            "sm:flex-row": sm

        }), stackProps)}
        style={style}>
        {children}
    </div>
}

export function VStack({children, wHalf, style, ...stackProps}: PropsWithChildren<StackProps & { wHalf?: boolean }>) {
    return <div
        className={stackClassName(cns("flex flex-col", wHalf ? "md:w-1/2" : "w-full"), {...stackProps, hFull: true})}
        style={style}>
        {children}
    </div>
}

export function GradientBox({
                                children,
                                style,
                                maxWidth = 25,
                                maxHeight = 40,
                                ...stackProps
                            }: PropsWithChildren<StackProps & { maxWidth?: number, maxHeight?: number }>) {
    return <div className={stackClassName("flex flex-col h-full w-full rounded-2xl p-10", stackProps)}
                style={{
                    background: "linear-gradient(111deg, rgba(202, 240, 248, 0.80) -5.88%, rgba(255, 255, 255, 0.80) 19.61%, rgba(255, 255, 255, 0.80) 49.34%, rgba(202, 240, 248, 0.80) 91.69%)",
                    boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.20)",
                    maxHeight: `${maxHeight}rem`,
                    maxWidth: `${maxWidth}rem`,
                    ...(style || {})
                }}>
        {children}
    </div>
}

export function Box({children, style, ...stackProps}: PropsWithChildren<StackProps>) {
    return <div className={stackClassName("flex flex-col border-solid border-gray-300 rounded-2xl p-6", stackProps)}
                style={{background: `${COLOR_WHITE}`, ...(style || {})}}>

        {children}
    </div>
}

export function OutlineBox({children, style, ...stackProps}: PropsWithChildren<StackProps>) {
    return <div className={stackClassName("flex flex-col rounded-2xl p-2 m-4 border-b-gray-400 border-2", stackProps)}
                style={style}>
        {children}
    </div>
}

export function AbsoluteCenterStack({children}: PropsWithChildren) {
    return <div
        className="absolute h-full w-full top-0 left-0"
    >
        <HStack center>
            <VStack center>
                {children}
            </VStack>
        </HStack>
    </div>
}

export function HSpacer() {
    return <div className="w-full"/>
    // return <div className="w-full"/>
}

export function VSpacer() {
    return <div className="h-full"/>
}

export function LinkRow({children}: PropsWithChildren) {
    return <div
        className="flex flex-row p4 px8 cursor-pointer hover:bg-neutral-200 border border-b-neutral-200 transition last:border-b-0">
        {children}
        <span
            className="font-2xl p-2 -rotate-90 text-neutral-400"
        >&gt;</span>
    </div>
}

export function SearchResultRow({children}: PropsWithChildren) {
    return <div
        className="flex flex-row items-center p-4 px-8 min-h-12 border-b border-b-neutral-200 last:border-b-0 hover:bg-neutral-100 even:bg-neutral-50 cursor-pointer">
        {children}
    </div>
}

type TextProps = {
    center?: boolean
    normal?: boolean
    wFull?: boolean
    inverse?: boolean
    light?: boolean
    block?: boolean
    medium?: boolean
    red?: boolean
    green?: boolean
    m0?: boolean
    classNames?: string
}

function textClassName(cn: string, {
    block,
    medium,
    center,
    normal,
    wFull,
    inverse,
    classNames,
    light,
    m0,
    red,
    green
}: TextProps) {
    return cns(classNames, cn, {
        "text-normal": normal && !medium,
        "text-center": center,
        "text-white": inverse,
        "w-full": wFull,
        "shrink-0": wFull,
        "block": block,
        "font-light": light && !medium && !normal,
        "font-medium": medium,
        "m-0": m0,
        "p-0": m0,
        "py-4": !m0,
        "my-4": !m0,
        "text-error": red,
        "text-success": green && !red
    })
}

export function TextBase({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-base", textProps)}>{children}</span>
}


export function TextError({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-lg text-red-500", {...textProps, m0: true})}>{children}</span>
}

export function TextSm({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span
        className={textClassName("text-sm leading-5 text-neutral-600", {...textProps, m0: true})}>{children}</span>
}

export function TextXs({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-xs text-neutral-600", {...textProps, m0: true})}>{children}</span>
}

export function TextCode({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span
        className={textClassName("text-sm leading-5 font-mono tab", {...textProps, m0: true})}>{children}</span>
}

export function TextSmItalic({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-sm leading-5 text-neutral-600 italic", {
        ...textProps,
        m0: true
    })}>{children}</span>
}
export function TextXsItalic({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-xs leading-5 text-neutral-600 italic", {
        ...textProps,
        m0: true
    })}>{children}</span>
}

export function Text5Xl({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-5xl", {light: true, ...textProps})}>{children}</span>
}

export function Text4Xl({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-4xl", {light: true, ...textProps})}>{children}</span>
}

export function Text3Xl({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-3xl", {light: true, ...textProps})}>{children}</span>
}

export function Text2Xl({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-2xl", textProps)}>{children}</span>
}

export function TextXl({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-xl", textProps)}>{children}</span>
}

export function TextLg({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-lg", textProps)}>{children}</span>
}

export function TextR({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-base md:text-lg lg:text-xl", textProps)}>{children}</span>
}

export function TextRLg({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-xl md:text-2xl lg:text-3xl", textProps)}>{children}</span>
}

export function TextRSm({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span className={textClassName("text-sm lg:text-base", textProps)}>{children}</span>
}

export function TextLink({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span
        className={textClassName("text-base text-blue-400 underline", {...textProps, m0: true})}>{children}</span>
}
export function TextLinkSm({children, ...textProps}: PropsWithChildren<TextProps>) {
    return <span
        className={textClassName("text-sm text-blue-400 underline", {...textProps, m0: true})}>{children}</span>
}

export function CollapseContent({children}: PropsWithChildren) {
    return <div className="px-14">
        {children}
    </div>
}

export function Header({children}: PropsWithChildren) {
    return <div className="flex flex-col h-20 text-center">{children}</div>
}

export function Card({title, imgSrc, width, children}: PropsWithChildren<{
    title: string,
    imgSrc: string,
    width?: string
}>) {
    return <div
        style={{
            background: 'linear-gradient(119deg, rgba(0, 121, 233, 0.00) 46.36%, rgba(0, 121, 233, 0.24) 100%)'
        }}
        className={`flex flex-col rounded-2xl p-6 m-4 ${width ? "w-" + width : ""} ${width ? "min-w-" + width : ""}`}>
        <div className="flex flex-row">
            <img className="w-16 h-16 mr-14"
                 src={imgSrc}
                 alt=""/>
            <TextXl>{title}</TextXl>
        </div>
        {children}
    </div>
}

export function ProjectIcon({rec_number, lg, sm}: { rec_number: string, lg?: boolean, sm?: boolean}) {
    const imgSrc = `/recommendation-icons/${rec_number}.png`
    return <img className={classNames({
        "h-16": lg,
        "h-8": !lg && !sm,
        "h-4": sm,
        "w-16": lg,
        "w-8": !lg && !sm,
        "w-4": sm
    })}
                src={imgSrc}
                alt=""/>
}

export function ProjectIcons({rec_numbers, lg, sm}: { rec_numbers: Array<string>, lg?: boolean, sm?: boolean}) {
    return <HStack leftCenter shrink classNames="mr-8 flex-grow-0">
        {
            Array.from(new Set(rec_numbers))
                .sort((r1, r2) => parseInt(r1) - parseInt(r2))
                .map((r, i) => <React.Fragment key={r}>
                    <ProjectIcon lg={lg} sm={sm} rec_number={r}/>
                    {i != rec_numbers.length - 1 && <TextBase>+</TextBase>}
                </React.Fragment>)
        }
    </HStack>
}

export function HDivider() {
    return <hr className="text-gray-400 my-5 w-full"/>
}


export function DesignedHeaderFooterCap() {
    return <div style={{backgroundColor: PRIMARY_LIGHT_BLUE}} className="h-2 max-h-2 w-full flex-grow-0"/>
}

export function FullWithDesignedSection({
                                            children,
                                            background,
                                            minHeight,
                                            absolute,
                                            grow,
                                            classNames: cns
                                        }: PropsWithChildren<{
    background: string,
    minHeight?: string,
    absolute?: boolean,
    grow?: boolean,
    classNames?: string
}>) {
    return <section className={classNames("w-full py-24 px-2 sm:px-8 xl:px-24 lg:px-12", {
        "absolute": absolute,
        "top-0": absolute,
        "relative": !absolute,
        "flex-grow": grow,
        "flex-grow-0": !grow
    }, cns)}
                    style={{
                        background,
                        minHeight
                    }}>
        {children}
    </section>
}

export function PageSection({title, imgSrc, headerComponent, children}: PropsWithChildren<{
    title: string,
    imgSrc?: string,
    headerComponent?: ReactElement
}>) {
    return (
        <div className="w-full py-12 px-2 sm:px-8 xl:px-24 lg:px-12 bg-white flex-grow relative">
            <VStack>
                <HStack between>
                    <HStack>
                        {imgSrc && <img className="w-14 h-14 mr-10" src={imgSrc}/>}
                        <Text4Xl>{title}</Text4Xl>
                    </HStack>
                    {headerComponent}
                </HStack>
                {children}
            </VStack>
        </div>
    )
}

export function CenterView({children}: PropsWithChildren) {
    return <div className="flex flex-col items-center justify-center min-h-dvh py-5 px-3">{children}</div>
}