import {createFileRoute, Outlet} from '@tanstack/react-router'

import {Layout, SheetsDataProvider, UserLoggedInRedirectToSignInCheck} from "@seeair/shared-components";
import {Helmet} from "react-helmet-async";
import React from "react";

export const Route = createFileRoute('/homes/_homes')({
    component: function HomesBaseComponent() {
        return <UserLoggedInRedirectToSignInCheck>
            <SheetsDataProvider>
                <Layout>

                    <Helmet>
                        <title>SeeAir</title>
                        <meta name="description"
                              content="SeeAir Home"/>
                    </Helmet>
                    <Outlet/>
                </Layout>
            </SheetsDataProvider>
        </UserLoggedInRedirectToSignInCheck>
    }
})