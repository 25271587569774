import {
    Autocomplete,
    Button,
    CloseButton,
    Loader,
    MultiSelect, NumberInput,
    Select,
    Textarea,
    TextInput
} from "@mantine/core";

import {RecInputSchema, RecManualInputData, RecommendationTemplate} from "@seeair/schemas";
import {trpc} from "~/lib-client";
import {useForm, zodResolver} from "@mantine/form";
import {useContext, useMemo, useState} from "react";
import {Box, getUseMutationOpt, HStack, TextLg} from "@seeair/shared-components";
import {SheetsDataContext} from "@seeair/shared-components";
import React from 'react'

export function NewRecommendationInputPanel({home_id, assessment_id}: { home_id: string, assessment_id: string }) {
    const {recommendationsSheet} = useContext(SheetsDataContext)
    const [_templateLabel, _setTemplateLabel] = useState<string>('')
    const addRecForm = useForm<
        RecManualInputData
    >({
        initialValues: {
            title: '',
            description: '',
            category: undefined,
            type: undefined,
            original_rec_id: '',
            rec_data: {
                Annual_Savings_High: 0,
                Annual_Savings_Low: 0,
                Net_Cost_Low: 0,
                Net_Cost_High: 0,
                Upfront_Cost_Low: 0,
                Upfront_Cost_High: 0,
                Prerequisites: [],
            },
        },
        validate: zodResolver(RecInputSchema),
    })
    const {
        isPending: isAddRecPending,
        isError: isAddRecError,
        error: addRecError,
        mutate: addRec
    } = trpc.ADMIN.addRecommendations.useMutation(getUseMutationOpt(trpc.useUtils(),()=>addRecForm.reset()))
    const recFmt = (rec: RecommendationTemplate) => `${rec.id} - ${rec.title}`
    const categories = useMemo(
        () => [...new Set(recommendationsSheet.map((rec) => rec.category))],
        [recommendationsSheet]
    )
    const types = ['DIY', 'PRO']
    return <Box>
        <TextLg>Add recommendation</TextLg>
        <HStack>
            <Autocomplete
                label="Choose template"
                maxDropdownHeight={350}
                data={recommendationsSheet.map((rec) => ({
                    value: rec.id,
                    label: recFmt(rec),
                }))}
                value={_templateLabel}
                onChange={(label) => _setTemplateLabel(label)}
                onOptionSubmit={(id) => {
                    const found = recommendationsSheet.find((rec) => rec.id === id)
                    if (!found) {
                        throw new Error('Failed to find matching rec')
                    }

                    addRecForm.setValues({
                        category: found.category,
                        title: found.title,
                        description: found.description,
                        original_rec_id: found.id,
                        type: found.type,
                        rec_data: {
                            Prerequisites: found.prerequisites,
                            Annual_Savings_High: 0,
                            Annual_Savings_Low: 0,
                            Net_Cost_High: 0,
                            Net_Cost_Low: 0,
                            Upfront_Cost_High: 0,
                            Upfront_Cost_Low: 0,
                        },
                    })
                }}
                rightSection={
                    _templateLabel && (
                        <CloseButton
                            onClick={() => _setTemplateLabel('')}
                        />
                    )
                }
            />
            <Button onClick={() => {
                _setTemplateLabel('')
                addRecForm.reset()
            }}
            >
                Clear form
            </Button>
        </HStack>
        <form
            className="grid-cols-12"
            onSubmit={addRecForm.onSubmit((_values) => {
                const newRec = RecInputSchema.parse(_values)
                addRec({home_id, assessment_id, recommendations: [newRec]})
            })}
            onReset={addRecForm.onReset}
        >
            <TextInput
                label="Title"
                className="col-span-6"
                {...addRecForm.getInputProps('title')}
            />
            {/* <div className={css({gridColumn: 'auto / span 1'})} /> */}
            <Select
                label="Category"
                className="col-span-3"
                data={categories}
                {...addRecForm.getInputProps('category')}
            />
            <Select
                label="Type"
                data={types}
                className="col-span-2"
                {...addRecForm.getInputProps('type')}
            />
            <Textarea
                label="Description"
                className="col-span-6"
                resize="vertical"
                {...addRecForm.getInputProps('description')}
            />
            <MultiSelect
                label="Prerequisites"
                className="col-span-6"
                data={recommendationsSheet.map((rec) => ({
                    value: rec.id,
                    label: `${rec.id} - ${rec.title}`,
                }))}
                {...addRecForm.getInputProps('rec_data.Prerequisites')}
            />
            <NumberInput
                label="Upfront_Cost_Low"
                className="col-span-3"
                {...addRecForm.getInputProps('rec_data.Upfront_Cost_Low')}
            />
            <NumberInput
                label="Upfront_Cost_High"
                className="col-span-3"
                {...addRecForm.getInputProps('rec_data.Upfront_Cost_High')}
            />
            <NumberInput
                label="Net_Cost_Low"
                className="col-span-3"
                {...addRecForm.getInputProps('rec_data.Net_Cost_Low')}
            />
            <NumberInput
                label="Net_Cost_High"
                className="col-span-3"
                {...addRecForm.getInputProps('rec_data.Net_Cost_High')}
            />
            <NumberInput
                label="Annual_Savings_Low"
                className="col-span-3"
                {...addRecForm.getInputProps('rec_data.Annual_Savings_Low')}
            />
            <NumberInput
                label="Annual_Savings_High"
                className="col-span-3"
                {...addRecForm.getInputProps('rec_data.Annual_Savings_High')}
            />
            <div className="col-span-12">
                <Button type="submit" disabled={isAddRecPending}>
                    {isAddRecPending ? <Loader/> : "Save"}
                </Button>
            </div>
        </form>
    </Box>
}