import React, {PropsWithChildren} from "react";

type UtmData = {
    utm_source: string
    utm_content: string
}
const DefaultUtmData: UtmData = {utm_source: "none", utm_content: "none"}
export const UtmDataContext = React.createContext<UtmData>(DefaultUtmData)
export function UtmDataProvider({data, children}: PropsWithChildren<{ data: UtmData }>) {
    return <UtmDataContext.Provider value={data}>
        {children}
    </UtmDataContext.Provider>
}